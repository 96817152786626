import { Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import PageLoader from "./Components/Loader/PageLoader";
import Navigation from "./Components/Navigation/TopNavigation";

import Main from "./Pages/Main";
import { Container } from "./styled-component/MainComponent";
import Footer from "./Components/Footer/Footer";
import Testing from "./Pages/Testing";
import Portfolio from "./Pages/Portfolio";
import ProjectDetails from "./Pages/ProjectDetails";
import About from "./Pages/About";
import Events from "./Pages/Events";
import OurServices from "./Pages/OurServices";
import Missing from "./Pages/Missing";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000)
  }, []);
  return (
    <>
      {
        loading ?
          <PageLoader />
          :
          <Container>
            <Navigation />
            <Routes>
              <Route path="/" element={<Main />} scrollToTop />
              <Route path="test" element={<Testing />} scrollToTop />
              <Route path="portfolio" element={<Portfolio />} scrollToTop />
              <Route path="about" element={<About />} scrollToTop />
              <Route path="event" element={<Events />} scrollToTop />
              <Route path="services" element={<OurServices />} scrollToTop />
              <Route path="/portfoliodetails/:slug" element={<ProjectDetails />} scrollToTop />
              <Route path="*" element={<Missing />} />
            </Routes>
            <Footer />
             
          </Container>

      }


    </>

  );
}

export default App;
