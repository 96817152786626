import React from 'react'
import { ContentCard, ContentCardFlex, ContentCardImage, ContentWrapper } from './Content'
import { OverTitle } from '../Overview/Overview'

const SketchedContent = ({ title, sketchedImage }) => {
    return (
        <ContentWrapper>
            <OverTitle> {title}</OverTitle>
            <ContentCardFlex>
                {
                    sketchedImage.map((item, index) => (
                        <ContentCard key={index}>
                            <ContentCardImage src={item} />
                            {/* <ContentCardWord>Photo booth design dimensions</ContentCardWord> */}
                        </ContentCard>
                    ))
                }
            </ContentCardFlex>
        </ContentWrapper>
    )
}

export default SketchedContent