import styled,  { keyframes } from "styled-components";
import { MainHeaderTag } from "./MainComponent";

const scroll = keyframes`
0% { transform: translateX(0); }
100% { transform: translateX(calc(-250px * 7 ))}
`;

export const Slider =  styled.div`
    height: auto;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;

`;
export const SlideTrack =  styled.div`
animation: ${scroll} 40s linear infinite;
display: flex;
width: 100%;
gap: 15px;

@media (max-width: 575px) {
    margin: 0 20px;
}

`;


export const SlideImg = styled.img`
width: 140.86px;
height: 48px;
color: #000000;
`;

export const BannerWrapper = styled.div`
    margin: 5px;
    @media (max-width: 575px) {
        margin-top: 30px;
    }
`;

export const BannerTitle = styled(MainHeaderTag)`
    text-align: center;
    @media (max-width: 575px) {
        margin: auto;
        width: 70%;
        margin-bottom: 2rem;
    }
`;
