import React, { useState, useEffect } from 'react'
import { CenterReviewNave, ReviewArrowButton, ReviewCard, ReviewCompany, ReviewName, ReviewNav, ReviewWrapper } from './Reviewstyles'
import { AutoDiv, FlexDivSpace, MainHeaderTag, MainPTag, MosardFlex, SectionBody } from '../../styled-component/MainComponent'
import { ExtentContactImage } from '../Navigation/NavigationStyled';
import reviews from '../../Utils/Reviews';

const AllReviews = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        }, 15000);

        return () => clearInterval(timer);
    }, []);

    //handle click effect below
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };

    //review display
    const AllSavedReviews = () => {
        const savedReviews = [
            reviews[currentIndex % reviews.length],
            reviews[(currentIndex + 1) % reviews.length]
        ];

        return savedReviews.map((reviews, index) => (
            <ReviewCard key={index}>
                <MainPTag>{reviews.message}</MainPTag>
                <ReviewName>{reviews.name} </ReviewName>
                <ReviewCompany>{reviews.company}</ReviewCompany>
            </ReviewCard>
        ));

    };
    return (
        <ReviewWrapper>
            <SectionBody>
                <FlexDivSpace>
                    <div>
                        <MainHeaderTag style={{ margin: '0', color: '#ffffff' }}>Reviews from our clients </MainHeaderTag>
                        <MainPTag>We take our clients on a creative journey, expressing excellence all the way</MainPTag>
                    </div>
                    <AutoDiv>
                        <ReviewArrowButton onClick={handlePrev}>
                            <ExtentContactImage src='images/icons/back-white.png' />
                        </ReviewArrowButton>
                        <ReviewArrowButton onClick={handleNext}>
                            <ExtentContactImage src='images/icons/front-white.png' />
                        </ReviewArrowButton>
                    </AutoDiv>
                </FlexDivSpace>

                <MosardFlex>
                    {AllSavedReviews()}
                </MosardFlex>
                <CenterReviewNave>
                    <ReviewNav activeRev={currentIndex === 1 || currentIndex === 0 ? 'true' : ''} />
                    <ReviewNav activeRev={ currentIndex === 2 || currentIndex === 3 ? 'true' : ''}/>
                </CenterReviewNave>
            </SectionBody>
        </ReviewWrapper>
    )
}

export default AllReviews