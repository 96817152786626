import React from 'react'
import Lottie from 'react-lottie';
import Animation2 from '../lotties/Animation2.json'

const Missing = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Animation2,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <Lottie
                options={defaultOptions}
                height={600}
                width={600}
            />
        </>
    )
}

export default Missing