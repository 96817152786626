import React, { useState, useCallback, useEffect } from 'react'
import { GiArtilleryShell, GiTravelDress } from 'react-icons/gi';
import { RiSettings5Fill } from 'react-icons/ri';
import { AiOutlineNodeCollapse, AiFillPropertySafety } from 'react-icons/ai';
import { MdAnimation, MdBrandingWatermark } from 'react-icons/md';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RxEyeClosed } from "react-icons/rx";
import { VscEye } from "react-icons/vsc";
import ServiceBanner from '../Components/Banner/ServiceBanner'
import { FlexDivCenter, MainButtonLink, SectionBody } from '../styled-component/MainComponent'
import { AboutDivWrap, AboutPageFlex, AboutSliderWord, AboutTitle, AboutWord, ServiceAccordionTitle, ServiceAccordionPTage, ServiceTalkLaterDiv, ServiceTalkPTage } from '../Components/Banner/banner'
import { BannerWrapper, SlideTrack, Slider } from '../styled-component/BannerStyled';
import services from '../Utils/Services';
import ContactModal from '../Components/Contact/ContactModal';
import { projects } from '../Utils/project'
import { Grid, useMediaQuery } from '@mui/material'
import ProjectCard from '../Components/Card/ProjectCard';

const OurServices = () => {
    const [expanded, setExpanded] = useState('service1');
    const [visible, setVisible] = useState(false);

    const show = () => {
        setVisible(true);
    }

    const handleChange = useCallback((value) => {
        setExpanded(value);
    }, [setExpanded]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    //handle the view work slide start
    const [currentIndex, setCurrentIndex] = useState(0);
    const isMobile = useMediaQuery('(max-width:575px)');

    //filter to get all the featured project
    const FeaturedProjects = projects.filter((project) => project.feature === 1);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % FeaturedProjects.length);
        }, 10000);

        return () => clearInterval(timer);
    }, [FeaturedProjects.length]);

    const featuredProjects = () => {
        const currentProject = [];

        if (isMobile) {
            for (let i = 0; i < 2; i++) {
                const projectIndex = (currentIndex + i) % FeaturedProjects.length;
                const project = FeaturedProjects[projectIndex];

                currentProject.push(
                    <Grid item xs={6} sm={6} md={4} sx={{ paddingLeft: { xs: '5px !important' }, width: '100%' }}>
                        <ProjectCard key={projectIndex} project={project} />
                    </Grid>

                );
            }
        } else {
            for (let i = 0; i < 3; i++) {
                const projectIndex = (currentIndex + i) % FeaturedProjects.length;
                const project = FeaturedProjects[projectIndex];

                currentProject.push(
                    <Grid item xs={6} sm={6} md={4} sx={{ paddingLeft: { xs: '5px !important' }, width: '100%' }}>
                        <ProjectCard key={projectIndex} project={project} />
                    </Grid>

                );
            }
        }


        return currentProject;
    };

    return (
        <>
            <ServiceBanner />

            <BannerWrapper>
                <Slider>
                    <SlideTrack>
                        <AboutSliderWord>creativity</AboutSliderWord>
                        <AboutSliderWord>. </AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord >creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord >creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord >creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                    </SlideTrack>
                </Slider>
            </BannerWrapper>
            <SectionBody>
                <AboutTitle grey >
                    Our Artistic Team and Services
                </AboutTitle>
                <div>
                    {
                        services.map((item, index) => (
                            <Accordion expanded={expanded === item.id} onChange={() => handleChange(item.id)}>
                                <AccordionSummary
                                    expandIcon={expanded === item.id ? <VscEye /> : <RxEyeClosed />}
                                    aria-controls={item.id}
                                    id={item.id}
                                >
                                    <ServiceAccordionTitle>{item.title}</ServiceAccordionTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ServiceAccordionPTage>
                                        {item.des}
                                    </ServiceAccordionPTage>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </div>

                <ServiceTalkLaterDiv>
                    <ServiceTalkPTage>
                        Collaborate with us to turn your
                        vision into a masterpiece
                    </ServiceTalkPTage>
                    <FlexDivCenter style={{ marginTop: '25px' }}>
                        <MainButtonLink onClick={show}>LET'S TALK</MainButtonLink>
                    </FlexDivCenter>
                    <ContactModal visible={visible} setVisible={setVisible} />
                </ServiceTalkLaterDiv>
            </SectionBody>
            <SectionBody>
                <AboutTitle grey >
                    CHECK OUT OUR WORKS
                </AboutTitle>
                <Grid container spacing={2} sx={{ marginLeft: { sm: '0px !important', xs: '0px !important' }, margin: { sm: '0px !important', xs: '0px !important' }, width: { xs: '100% !important' } }}>
                    {featuredProjects()}
                </Grid>
            </SectionBody>
            {/* <SectionBody>
                <AboutPageFlex>
                    <AboutDivWrap>
                        <AboutTitle>
                            ART DIRECTOR <GiArtilleryShell style={{width:"25px", height:"25px", color: '#B41B00'}} />
                        </AboutTitle>
                        <AboutWord>
                            Art direction is the person in charge of execution of the production designers visions and the head of the art department
                        </AboutWord>
                    </AboutDivWrap>
                    <AboutDivWrap>
                        <AboutTitle>
                            PRODUCTION DESIGNER 
                            
                        </AboutTitle>
                        <AboutWord>
                            Productions designer is responsible for the over all visual of creative designs, look, feel and creative concept, he interpret the moodboard etc
                        </AboutWord>
                    </AboutDivWrap>
                </AboutPageFlex>
            </SectionBody>
            <SectionBody>
                <AboutPageFlex>
                    <AboutDivWrap>
                        <AboutTitle>
                            SET DESIGNER <RiSettings5Fill style={{width:"25px", height:"25px", color: '#B41B00'}} />
                        </AboutTitle>
                        <AboutWord>
                        A Set designer create sets for theatre, film, and television. They work closely with the director and set builders to conceptualize, design, and develop the overall look of the production. Set designers read scripts, complete the set layout, order materials, and ensure all actors and staff are briefed on safety and are aware of how the set works.
                        </AboutWord>
                    </AboutDivWrap>
                    <AboutDivWrap>
                        <AboutTitle>
                            SET DECORATOR <AiOutlineNodeCollapse style={{width:"25px", height:"25px", color: '#B41B00'}} />
                        </AboutTitle>
                        <AboutWord>
                        Set Decorators are Key Department Heads, hired by the Production Designer for their creative talent to implement the Production Designer’s vision and the visual interpretation of the script and its characters; and for technical skills and expertise in project management including budgeting, hiring, scheduling and organizing the Set Decoration Department staff. The set decorator is responsible for each décor element inside the sets, from practical lighting, technology, art, furniture, drapery, floor coverings, books, collectables, to exterior furnishings
                        </AboutWord>
                    </AboutDivWrap>
                </AboutPageFlex>
            </SectionBody>
            <SectionBody>
                <AboutPageFlex>
                    <AboutDivWrap>
                        <AboutTitle>
                            3D DESIGNER <MdAnimation style={{width:"25px", height:"25px", color: '#B41B00'}} />

                        </AboutTitle>
                        <AboutWord>
                        A 3D designer designs and creates three-dimensional models based on client specifications. This type of work involves developing new 3D products, improving old designs and working with the latest computer-aided design (CAD) tools.
                        </AboutWord>
                    </AboutDivWrap>
                    <AboutDivWrap>
                        <AboutTitle>
                        BRANDING AGENT <MdBrandingWatermark style={{width:"25px", height:"25px", color: '#B41B00'}} />
                        </AboutTitle>
                        <AboutWord>
                        Branding agents guide client companies through the process of re-branding or brand enhancement. They collaborate with client companies to assist them in achieving certain goals concerning branding.
                        </AboutWord>
                    </AboutDivWrap>
                </AboutPageFlex>
            </SectionBody>
            <SectionBody>
                <AboutPageFlex>
                    <AboutDivWrap>
                        <AboutTitle>
                        SET DRESSER <GiTravelDress style={{width:"25px", height:"25px", color: '#B41B00'}} />

                        </AboutTitle>
                        <AboutWord>
                        The set dresser is the individual who creates a “lived-in” quality for every scene by preparing, picking up, and delivering an assortment of props, decor items, materials and furniture for the set, all in accordance with the visions of the production designer and set decorator. It’s largely on the shoulders of the set dresser to help make the world of each film feel convincing to the audience and adjust the look of a location to suit the tone of the project
                        </AboutWord>
                    </AboutDivWrap>
                    <AboutDivWrap>
                        <AboutTitle>
                        PROPERTY MASTER <AiFillPropertySafety style={{width:"25px", height:"25px", color: '#B41B00'}} />
                        </AboutTitle>
                        <AboutWord>
                        The Props Designer/ Manager is responsible for obtaining all props needed for the production. They also work with the Stage Manager in gathering appropriate rehearsal props early in the rehearsal process. They may also be called upon to help the Set Designer dress the set.
                        </AboutWord>
                    </AboutDivWrap>
                </AboutPageFlex>
            </SectionBody> */}
        </>
    )
}

export default OurServices