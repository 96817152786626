import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { projects } from '../Utils/project'
import FullPortfolioDetails from './PortfolioPages/FullPortfolioDetails'
import NovideoNoSketch from './PortfolioPages/NovideoNoSketch'

const ProjectDetails = () => {
  const { slug } = useParams();
  const [portfolio, setPortfolio] = useState({});

  const getProjectBySlug = (slug) => {
    return projects.find((project) => project.slug === slug);
  };

  useEffect(() => {
    setPortfolio(getProjectBySlug(slug));
    window.scrollTo(0, 0);
  }, [slug]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      {
        !portfolio.video_link &&
        <NovideoNoSketch portfolio={portfolio} />
      }
      {
        portfolio.video_link &&
        <FullPortfolioDetails portfolio={portfolio} />
      }
    </>
  )
}

export default ProjectDetails