import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const CardWrapper = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
@media (max-width: 575px) {
  // flex-direction: column
  // align-items: center;
  justify-content: normal;
  gap: 0.5em;
  }
`;
export const CardWrapper2 = styled.div`
display: flex;
justify-content: space-between;
// flex-wrap: wrap;
gap: 0.5em;
@media (max-width: 575px) {
  // flex-direction: column
  // align-items: center;
  justify-content: normal;
  gap: 0.5em;
  }
`;

export const PortfolioCardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;  
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${({ Open }) => (Open === 'true' ? '5px' : '5px')};
  @media (min-width: 992px) and (max-width: 1199px) {
    gap: 5px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    
  }

  @media (min-width: 576px) and (max-width: 767px) {
    gap: 5px;
    flex-direction: ${({ Open }) => (Open === 'true' ? 'column' : 'flex-start')}; 
  }
  @media (max-width: 575px) {
    // flex-direction: column
    }
`;

export const CardBody = styled(motion.div)`
  width: ${({ portfolio }) => (portfolio ? '100%' : '100%')};
  height: ${({ portfolio }) => (portfolio ? '' : '700px')} ;
  margin-bottom: 25px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${({ portfolio }) => (portfolio ? '100%' : '100%')}; 
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${({ portfolio }) => (portfolio ? '100%' : '100%')}; 
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${({ portfolio }) => (portfolio ? '100%' : '45%')}; 
  }
  @media (max-width: 575px) {
      // width: 80%;
      // width: 100%;
      height: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;
// export const CardImageWrapper = styled.div`
// background-image: url(${props => props.imageOld});
// background-size: cover;
// &:hover::before {
//   background-image: url(${props => props.imageSrc});
//   background-size: cover;
//   opacity: 0;
//   // transition: opacity 0.3s;
// }
// `;

export const CardImage = styled.img`
width: 100%;
height: 100%;
opacity: 0;
object-fit: contain;
transition: opacity 0.3s;
backface-visibility: hidden;
  &:hover {
    opacity: 10;
   
  }

@media (max-width: 575px) {
    width: 100%;
    height: 450px;
}
`;

export const CardImageWrapper = styled.div`
  width: 100%;
  height: 400px;
  background-image: url(${props => props.imageOld});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.3s ease;
  &:hover {
    background-image: url(${props => props.imageSrc});
  }
  @media (max-width: 575px) {
    width: 100%;
    height: 274px;
}
`;


export const CardTitle = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 36px;
color: #272727;
margin-bottom: 2px;
margin-top: 5px;
@media (max-width: 575px) {
  font-size: 16px;
  line-height: 26px;
}
`;

export const CardInfo = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
color: #2E2E2E;
  &:hover {
    color: #A1A1A1;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
export const CardInfoATag = styled(Link)`
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
color: #2E2E2E;
text-decoration: none;
margin: 1.4rem 0 0;
@media (max-width: 575px) {
  font-size: 10px;
  line-height: 16px;
  margin: 1rem 0;
}
`;

export const CardInfoAImage = styled.img`
width: 17.33px;
height: 17.33px;
color: #2E2E2E;
@media (max-width: 575px) {
  width: 12.33px;
  height: 12.33px;
}
`; 

export const CardLinkWrapper = styled(Link)`
text-decoration: none;
width: 100%;
`;

