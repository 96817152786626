import React, { useEffect, useState } from 'react'
import { SliderImage, SliderLink, SliderSection, SliderWordH1, SliderWordP, SliderWordWrapper } from './SliderStyles'
import { FlexDiv } from '../../styled-component/MainComponent';
// import { ProjectSliderIcon, ProjectSliderIconDiv2 } from '../Projects/Slider/Slider';
// import { projects } from '../../Utils/project';
let desktopImages = ["slider1.jpg", "slider2.jpg", "slider3.jpg", "slider4.jpg", "slider5.jpg", "slider6.jpg"];
let mobileImages = ["slider1-mobile.jpg", "slider2-mobile.jpg", "slider3-mobile.jpg", "slider4-mobile.jpg", "slider5-mobile.jpg", "slider6-mobile.jpg"];

const sliderData = [
    {
        title: "Projects",
        items1: ["Feature film", "Short film", "TV Show", "Interior design", "Commercial", "Documentary", ],
        items2: ["Magazine", "Set Construction", "3D Design", "Exterior Design", "Fashion Shoot", "Music video"],

    },
    {
        title: "Our Services",
        items1: ["Production design", "Art direction", "3D designs", "Set design", "Furniture making", "Mentoring and Training"],
        items2: ["Set dressing", "Set decoration", "Property master", "Props Rental"],
    },
    {
        title: "Projects",
        items1: ["Feature film", "Short film", "TV Show", "Interior design", "Commercial", "Documentary", ],
        items2: ["Magazine", "Set Construction", "3D Design", "Exterior Design", "Fashion Shoot", "Music video"],
    },
    {
        title: "Our Services",
        items1: ["Production design", "Art direction", "3D designs", "Set design", "Furniture making", "Mentoring and Training"],
        items2: ["Set dressing", "Set decoration", "Property master", "Props Rental"],
    },
    {
        title: "Projects",
        items1: ["Feature film", "Short film", "TV Show", "Interior design", "Commercial", "Documentary", ],
        items2: ["Magazine", "Set Construction", "3D Design", "Exterior Design", "Fashion Shoot", "Music video"],
    },
    {
        title: "Our Services",
        items1: ["Production design", "Art direction", "3D designs", "Set design", "Furniture making", "Mentoring and Training"],
        items2: ["Set dressing", "Set decoration", "Property master", "Props Rental"],
    },
];

const HomeSlider = () => {
    let [sliderImage, setSliderImage] = useState(0);
    const isMobile = window.innerWidth <= 575;

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (sliderImage === (isMobile ? mobileImages.length - 1 : desktopImages.length - 1)) {
                setSliderImage(0);
            } else {
                setSliderImage(sliderImage + 1);
            }
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [sliderImage, isMobile]);

    const images = isMobile ? mobileImages : desktopImages;
    const imagePath = `images/${images[sliderImage]}`;

    // sort categories to display categories
    // const categories = [...new Set(projects.map(project => project.category))];
    // const categories = ['Training', 'Mentoring', 'Stage Play', 'Feature Film', 'Music Video', 'Set Design', 'Commercial', 'Art consultation'];


    // // Shuffle the categories randomly
    // const shuffledCategories = categories.sort(() => 0.5 - Math.random());

    // // Get a random selection of 7 categories
    // const randomCategories = shuffledCategories.slice(0, 7);

    // // Sort the random categories alphabetically
    // const sortedCategories = shuffledCategories.sort();

    return (

        <>
            <SliderSection>
                <SliderImage src={imagePath} />
                <SliderWordWrapper
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 1,
                        delay: 1,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                >
                    <SliderWordH1>BRINGING <br /> SCENES TO LIFE</SliderWordH1>
                    <FlexDiv style={{ gap: '50px' }}>
                        <div>
                            <SliderWordP>{sliderData[sliderImage].title}</SliderWordP>
                            {/* {
                                sortedCategories.map((cat, index) => (
                                    <SliderLink key={index} to='portfolio'>{cat}</SliderLink>
                                ))
                            } */}

                            {sliderData[sliderImage].items1.map((item, itemIndex) => (
                                <SliderLink key={itemIndex} to='portfolio'>{item}</SliderLink>
                            ))}
                        </div>
                        <div>
                            <SliderWordP> &nbsp;</SliderWordP>
                            {sliderData[sliderImage].items2.map((item, itemIndex) => (
                                <SliderLink key={itemIndex} to='portfolio'>{item}</SliderLink>
                            ))}
                            {/* {sliderData[sliderImage].items2.map((item, itemIndex) => (
                                <SliderLink key={itemIndex} to='portfolio'>{item}</SliderLink>
                            ))} */}

                        </div>
                    </FlexDiv>
                    {/* <div className="" style={{ display: 'flex' }}>
                        <ProjectSliderIconDiv2>
                            {
                                images.map((item, index) => (
                                    <ProjectSliderIcon key={index} activeRev={index === sliderImage ? 'true' : 'false'} />
                                ))
                            }
                        </ProjectSliderIconDiv2>
                    </div> */}
                </SliderWordWrapper>
            </SliderSection>
        </>
    )
}

export default HomeSlider