import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const bouncy = keyframes`

0% {
    transform: translateY(20px);
    opacity: 0;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  // overflow-x: hidden; 
  // overflow-y: auto; 
  // height: 100vh;
`;

export const LoaderMain = styled.div`
display: flex;
justify-content: center;
align-items: center;
min-height: 100vh;
`;

export const LoaderH3 = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 700;
font-size: 70px;
line-height: 64px;
margin: 0;
z-index: 15;
`;
export const LoaderH3Bounce = styled(LoaderH3)`
animation: ${bouncy} 2s ease-in infinite;
`;

export const LoaderP = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.01em;
color: #01001B;
text-align: center;
`;

export const FlexDiv = styled.div`
display: flex;
`;
export const FlexDivCenter = styled.div`
display: flex;
justify-content: center;
`;
export const FlexDivSpace = styled.div`
display: flex;
justify-content: space-between;
@media (max-width: 575px) {
  
  }
`;

export const FlexDivSpacePort = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
width: 70%;
@media (max-width: 575px) {
  width: 100%;
  padding: 10px;
  }
`;
export const PortWidth = styled.div`
width: 33%;
@media (max-width: 575px) {
  width: 48%;
  }
`;



export const MainPTag = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.01em;
color: #FFFFFF;
margin: 0;

@media (min-width: 992px) and (max-width: 1199px) {
  font-size: 18px;
}

@media (min-width: 768px) and (max-width: 991px) {
  font-size: 18px;
}

@media (min-width: 576px) and (max-width: 767px) {
  font-size: 16px;
}
@media (max-width: 575px) {
  font-size: 14px;
  line-height: 20px;
}
`;

export const SectionBody = styled.section`
    padding: 5rem 8rem;
    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 3rem 5rem;
    }
    
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 2rem 3rem;
    }
    
    @media (min-width: 576px) and (max-width: 767px) {
      padding: 2rem 3rem;
    }
    @media screen and (max-width: 575px) {
        padding: 1.5rem 1rem;
    }
`;

export const MainButton = styled(motion.button)`
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
color:  ${({ outline }) => (outline ==='true' ? "#B41B00" : "#FFFFFF")} ;
padding: 12px 24px;
width: 293px;
height: 60px;
background:  ${({ outline }) => (outline ==='true' ? "#FFFFFF" : "#B41B00 ")} ;
border: ${({ outline }) => (outline ==='true' ? "1px solid #B41B00" : "transparent")}  ;
border-radius: 20px;
cursor: pointer;
`;
export const MainButtonLink = styled(Link)`
display: inline-block;
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 46px;
letter-spacing: 0.04em;
text-transform: uppercase;
color:  ${({ outline }) => (outline ==='true' ? "#B41B00" : "#FFFFFF")} ;
padding: 4px;
width: 285px;
height: 50px;
background:  ${({ outline }) => (outline ==='true' ? "#FFFFFF" : "#B41B00 ")} ;
border: ${({ outline }) => (outline ==='true' ? "1px solid #B41B00" : "transparent")}  ;
border-radius: 20px;
cursor: pointer;
text-decoration: none;
display: inline-block;
text-align: center;
&:hover {
  background:  ${({ outline }) => (outline ==='true' ? "#B41B00" : "#FFFFFF")};
  border: ${({ outline }) => (outline ==='true' ? "transparent" : "1px solid #B41B00")};
  color:  ${({ outline }) => (outline ==='true' ? "#FFFFFF" : "#B41B00")};
}
@media (min-width: 992px) and (max-width: 1199px) {
  
}

@media (min-width: 768px) and (max-width: 991px) {
  
}

@media (min-width: 576px) and (max-width: 767px) {
  width: 225.81px;
  height: 48px;
  font-size: 18px;
}
@media (max-width: 575px) {
  width: 225.81px;
  height: 48px;
  font-size: 15px;
  padding: 1px 1px;
}
`;

export const MainHeaderTag = styled.p`
font-family: 'Baguede Free';
font-style: normal;
font-weight: 400;
font-size: 64px;
line-height: 80px;
color: #272727;
@media (max-width: 998px) {
  font-size: 40px;
  line-height: 44px;
}
@media (max-width: 787px) {
  font-size: 38px;
  line-height: 44px;
}
@media (max-width: 575px) {
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
}
`;

export const AutoDiv = styled.div`
margin: auto 0; 
@media (max-width: 575px) {
width: 30%;
}
`;

export const ArrowButton = styled.button`
border: transparent;
background-color: white;
cursor: pointer;
`;

export const CenterButton = styled.div`
display: flex;
justify-content: center;
margin-top: 10px;
@media (max-width: 575px) {
  margin-top: 50px;
  }
`;

export const MosardFlex = styled.div`
display: flex;
justify-content: space-between;
gap: 5rem;
@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) and (max-width: 991px) {
  gap: 3rem;
}

@media (min-width: 576px) and (max-width: 767px) {
  gap: 3rem;
}
@media (max-width: 575px) {
  flex-direction: column;
  gap: 3rem;
  }
`;


export const MosardImg = styled.img`
width: 677px;
height: 845px;

@media (min-width: 992px) and (max-width: 1199px) {
  width: 550px;
  height: 550px;
}

@media (min-width: 768px) and (max-width: 991px) {
  width: 450px;
  height: 450px;
}

@media (min-width: 576px) and (max-width: 767px) {
  width: 300px;
  height: 300px;
}

@media (max-width: 575px) {
  width: 100%;
  height: 100%;
  }

`;


export const MosardWord = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 400;
font-size: 64px;
line-height: 80px;
color: #000000;
margin: 0;
@media (min-width: 992px) and (max-width: 1199px) {
  font-size: 56px;
  line-height: 75px;
}

@media (min-width: 768px) and (max-width: 991px) {
  font-size: 50px;
  line-height: 75px;
}

@media (min-width: 576px) and (max-width: 767px) {
  font-size: 36px;
  line-height: 50px;
}

@media (max-width: 575px) {
  font-size: 36px;
line-height: 44px;
  }
`;
export const MosardWordRed = styled(MosardWord)`
color: #B41B00;

`;

export const MosardAutoDiv = styled.div`
margin: auto 0; 
@media (max-width: 575px) {
  // margin-top: 2px;
  margin: auto 2px;
}`
;

export const MosardButtonDiv = styled.div`
margin-top: 30px;
@media (max-width: 575px) {
  margin-bottom: 30px;
}`
;

export const MosaidPlayImage = styled.img`
width: 17.33px;
height: 17.33px;
@media (max-width: 575px) {
  width: 12.33px;
  height: 12.33px;
  }
`; 

export const ReturnTopBtnDiv = styled.div`
position: absolute;
bottom: 12%;
right: 5%;
@media (min-width: 992px) and (max-width: 1199px) {
  
}

@media (min-width: 768px) and (max-width: 991px) {
  bottom: 3%;
}

@media (min-width: 576px) and (max-width: 767px) {
  bottom: 3%;
}
`;
export const ReturnTopBtnIcon = styled.img`
  width: 60px;
  height: 60px;
`;

export const EventHeaderWord = styled.p`
  color: #272727;
  font-family: Circular Std;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  margin: 0;
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px;
    line-height: 60px;
  }
  
  @media (max-width: 575px) {
    font-size: 20px;
    line-height: 30px;
    }
`;

export const EventtWord = styled.p`
    color: '#777' ;
    text-align:  center;
    margin-top: 0;
    font-size: 15px;
    @media (max-width: 575px) {
        font-size: 16px;
        line-height: 24px; /* 150% */
        letter-spacing: 0.16px;
      }
`;