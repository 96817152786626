// // import React, { useState, useEffect } from 'react';

// // const ProductSlider = () => {
// //   const [currentIndex, setCurrentIndex] = useState(0);

// //   const products = [
// //     {/* Product Card 1 */},
// //     {/* Product Card 2 */},
// //     {/* Product Card 3 */},
// //     {/* Product Card 4 */},
// //     {/* Product Card 5 */},
// //     {/* Product Card 6 */}
// //   ];

// //   useEffect(() => {
// //     const timer = setInterval(() => {
// //       setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
// //     }, 5000);

// //     return () => clearInterval(timer);
// //   }, [products.length]);

// //   const handleNext = () => {
// //     setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
// //   };

// //   const handlePrev = () => {
// //     setCurrentIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
// //   };

// //   const renderProducts = () => {
// //     const currentProducts = products.slice(currentIndex, currentIndex + 3);

// //     return currentProducts.map((product, index) => (
// //       <div key={index}>
// //         {/* Render Product Card */}
// //       </div>
// //     ));
// //   };

// //   return (
// //     <div className="product-slider">
// //       <div className="product-slider-container">
// //         {renderProducts()}
// //       </div>
// //       <div className="product-slider-controls">
// //         <button onClick={handlePrev}>Previous</button>
// //         <button onClick={handleNext}>Next</button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ProductSlider;


// // import React from 'react';
// // import styled from 'styled-components';

// // const RadioButtonWrapper = styled.label`
// //   display: flex;
// //   align-items: center;
// //   cursor: pointer;
// // `;

// // const RadioButtonInput = styled.input`
// //   display: none;
// // `;

// // const RadioButtonIcon = styled.span`
// //   position: relative;
// //   display: inline-block;
// //   width: 24px;
// //   height: 24px;
// //   border-radius: 50%;
// //   border: 2px solid #ccc;
// //   margin-right: 8px;

// //   &:after {
// //     content: '';
// //     position: absolute;
// //     top: 50%;
// //     left: 50%;
// //     transform: translate(-50%, -50%);
// //     width: 12px;
// //     height: 12px;
// //     border-radius: 50%;
// //     background-color: transparent;
// //     transition: background-color 0.3s;
// //   }
// // `;

// // const RadioButtonLabel = styled.span`
// //   font-size: 14px;
// //   color: #333;
// // `;

// // const Testing = ({ value, checked, onChange, label }) => {
// //   return (
// //     <RadioButtonWrapper>
// //       <RadioButtonInput
// //         type="radio"
// //         value={value}
// //         checked={checked}
// //         onChange={onChange}
// //       />
// //       <RadioButtonIcon style={{ backgroundColor: checked ? 'red' : '#ccc' }}>
// //         {/* {checked && <span style={{ backgroundColor: 'red' }}></span>} */}
// //       </RadioButtonIcon>
// //       <RadioButtonLabel>{label}</RadioButtonLabel>
// //     </RadioButtonWrapper>
// //   );
// // };

// // export default Testing;

// // import React from 'react';
// // import styled from 'styled-components';

// // const Form = styled.form`
// //   display: grid;
// //   gap: 1em;
// //   font-size: 2rem;
// // `;

// // const Label = styled.label`
// //   display: flex;
// //   align-items: center;
// // `;

// // const RadioInput = styled.input`
// //   font-size: inherit;
// //   width: 0.75em;
// //   height: 0.75em;
// //   background-color: red;
// // `;

// // const Testing = () => {
// //   return (
// //     <Form>
// //       <Label>
// //         <RadioInput type="radio" name="radios" defaultChecked />
// //         Checked
// //       </Label>
// //       <Label>
// //         <RadioInput type="radio" name="radios" />
// //         Unchecked
// //       </Label>
// //     </Form>
// //   );
// // };

// // export default Testing;

// import React from 'react';
// import styled from 'styled-components';

// const Container = styled.div`
//   display: block;
//   position: relative;
//   margin: 40px auto;
//   height: auto;
//   width: 500px;
//   padding: 20px;
// `;

// const Heading = styled.h2`
//   color: #AAAAAA;
// `;

// const List = styled.ul`
//   list-style: none;
//   margin: 0;
//   padding: 0;
//   overflow: auto;
// `;

// const ListItem = styled.li`
//   color: #AAAAAA;
//   display: block;
//   position: relative;
//   float: left;
//   width: 100%;
//   // height: 100px;
//   // border-bottom: 1px solid #333;
// `;

// const RadioInput = styled.input`
//   position: absolute;
//   visibility: hidden;
// `;

// const RadioLabel = styled.label`
//   display: block;
//   position: relative;
//   font-weight: 300;
//   font-size: 1.35em;
//   padding: 25px 25px 25px 80px;
//   margin: 10px auto;
//   height: 30px;
//   z-index: 9;
//   cursor: pointer;
//   -webkit-transition: all 0.25s linear;
//   transition: all 0.25s linear;
//   color: 'black';
// `;

// const RadioCheck = styled.div`
//   display: block;
//   position: absolute;
//   border: 24px solid  #AAAAAA;
//   border-radius: 100%;
//   height: 25px;
//   width: 25px;
//   top: 30px;
//   left: 20px;
//   z-index: 5;
//   transition: border 0.25s linear;
//   -webkit-transition: border 0.25s linear;
//   border-color: ${({ checked }) => (checked ? '#B41B00' : 'black')};
// `;

// const RadioCheckInside = styled.div`
//   display: block;
//   position: absolute;
//   content: '';
//   border-radius: 100%;
//   height: 15px;
//   width: 15px;
//   top: 5px;
//   left: 5px;
//   margin: auto;
//   transition: background 0.25s linear;
//   -webkit-transition: background 0.25s linear;
//   background: ${({ checked }) => (checked ? '#fff' : '')};
// `;


// const Testing = () => {
//   return (
//     <>

//       <Container className="container">
//         <Heading>Your favorite thing in the world:</Heading>
//         <List>
//           <ListItem>
//             <RadioInput type="radio" id="f-option" name="selector" defaultChecked />
//             <RadioLabel htmlFor="f-option" checked>
//               Pizza
//             </RadioLabel>
//             <RadioCheck checked>
//               <RadioCheckInside checked />
//             </RadioCheck>
//           </ListItem>

//           <ListItem>
//             <RadioInput type="radio" id="s-option" name="selector" />
//             <RadioLabel htmlFor="s-option">Bacon</RadioLabel>
//             <RadioCheck>
//               <RadioCheckInside />
//             </RadioCheck>
//           </ListItem>

//           <ListItem>
//             <RadioInput type="radio" id="t-option" name="selector" />
//             <RadioLabel htmlFor="t-option">Cats</RadioLabel>
//             <RadioCheck>
//               <RadioCheckInside />
//             </RadioCheck>
//           </ListItem>
//         </List>
//       </Container>
//     </>
//   );
// };

// export default Testing;



// // import React from 'react';
// // import products from './data';

// // function App() {
// //   // Fetch all product details
// //   const allProducts = products;

// //   // Fetch product by specific ID
// //   const getProductById = (id) => {
// //     return products.find((product) => product.id === id);
// //   };

// //   // Fetch products by specific category
// //   const getProductsByCategory = (category) => {
// //     return products.filter((product) => product.category === category);
// //   };

// //   // Example usage
// //   const productById = getProductById(1);
// //   const productsByCategory = getProductsByCategory('Category A');

// //   return (
// //     <div>
// //       {/* Render your components and display the fetched data */}
// //     </div>
// //   );
// // }

// // export default App;





// // return (
// //   <>
// //     <HomeSlider />
// //     <SectionBody>
// //       <FlexDivSpace>
// //         <div>
// //           <MainHeaderTag style={{ margin: '0' }}>FEATURED PROJECTS</MainHeaderTag >
// //           <MainPTag style={{ color: '#777777' }}>We exemplify excellence in design, innovation, and creativity.</MainPTag>
// //         </div>
// //         <AutoDiv>
// //           <ArrowButton onClick={handlePrev}>
// //             <ExtentContactImage src='images/icons/back.png' />
// //           </ArrowButton>
// //           <ArrowButton onClick={handleNext}>
// //             <ExtentContactImage src='images/icons/front.png' />
// //           </ArrowButton>
// //         </AutoDiv>
// //       </FlexDivSpace>

// //       <CardWrapper>
// //         {/* {images.map((image, index) => (
// //           <ProjectCard key={index} image={image.new} imageOld={image.old} />
// //         ))} */}
// //         {/* {featuredProjects()} */}
// //       </CardWrapper>

// //       <CenterButton>
// //         <MainButtonLink outline="true">view ALL PROJECTS <CardInfoAImage src='images/icons/frontmain.png' /></MainButtonLink>
// //       </CenterButton>
// //     </SectionBody>


// //       <BannerWrapper>
// //         <BannerTitle >BRANDS  THAT TRUST US</BannerTitle>
// //         <Slider>
// //           <SlideTrack>
// //             <SlideImg src='images/icons/amazon.png' />
// //             <SlideImg src='images/icons/bbn.png' />
// //             <SlideImg src='images/icons/bigibet.png' />
// //             <SlideImg src='images/icons/bluenun.png' />
// //             <SlideImg src='images/icons/heirs.png' />
// //             <SlideImg src='images/icons/ivvy.png' />
// //             <SlideImg src='images/icons/lafiya.png' />
// //             <SlideImg src='images/icons/mtn.png' />
// //             <SlideImg src='images/icons/mvca.png' />
// //             <SlideImg src='images/icons/netflix.png' />
// //             <SlideImg src='images/icons/redtv.png' />
// //             <SlideImg src='images/icons/showmax.png' />
// //             <SlideImg src='images/icons/sony.png' />
// //             <SlideImg src='images/icons/UNESCO.png' />
// //             <SlideImg src='images/icons/amazon.png' />
// //             <SlideImg src='images/icons/bbn.png' />
// //             <SlideImg src='images/icons/bigibet.png' />
// //             <SlideImg src='images/icons/bluenun.png' />
// //             <SlideImg src='images/icons/heirs.png' />
// //             <SlideImg src='images/icons/ivvy.png' />
// //             <SlideImg src='images/icons/lafiya.png' />
// //             <SlideImg src='images/icons/mtn.png' />
// //             <SlideImg src='images/icons/mvca.png' />
// //             <SlideImg src='images/icons/netflix.png' />
// //             <SlideImg src='images/icons/redtv.png' />
// //             <SlideImg src='images/icons/showmax.png' />
// //             <SlideImg src='images/icons/sony.png' />
// //             <SlideImg src='images/icons/UNESCO.png' />

// //           </SlideTrack>
// //         </Slider>
// //       </BannerWrapper>

// //     {/* <Testing /> */}
// //     <SectionBody>
// //       <MosardFlex>
// //         <MosardImg src='images/mosard.png' />
// //         <MosardAutoDiv>
// //           <MosardWord>
// //             Artist Commune
// //             <MosardWordRed>
// //               featured and interviewed
// //             </MosardWordRed>
// //             Big Mossad.
// //           </MosardWord>
// //           <MosardButtonDiv>
// //             <MainButtonLink><MosaidPlayImage src='images/icons/play.png' />  Watch Interview</MainButtonLink>
// //           </MosardButtonDiv>

// //         </MosardAutoDiv>
// //       </MosardFlex>
// //     </SectionBody>

// //     <AllReviews />

// //     <Contact />
// //   </>
// // )

import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
  width: 350px;
  height: 400px;
  background-image: url('images/Projects/african_foltales.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: background-image 0.3s ease;

  &:hover {
    background-image: url('images/Projects/african_foltales_b.png');
  }
`;
const Testing = () => {
  return <Container />;
}

export default Testing