import React from 'react'
import { RapperImage, TopRapper } from './Topstyles'
import { SliderWordH1 } from '../../Sliders/SliderStyles'

const ImageComponent = () => {
  return (
    <TopRapper>
        <RapperImage>
            <SliderWordH1 project>THIS THING CALLED LOVE BTS</SliderWordH1>
        </RapperImage>
    </TopRapper>
  )
}

export default ImageComponent