import React from 'react'
import { OverTitle, OverViewFlex, OverViewLabel, OverViewRapper, OverViewSideDiv, OverViewSideImage, OverViewText, OverViewTitle, OverViewWordsWrapper } from './Overview'

const ProjectOverview = ({withImage, title, client, year, role, image}) => {
  return (
    <OverViewRapper>
        <OverTitle>PROJECT OVERVIEW</OverTitle>
        <OverViewFlex>
            {
                withImage &&
                <OverViewSideImage src={image} />
            }
           
            <OverViewSideDiv>
                <OverViewWordsWrapper>
                    <OverViewLabel withImage>PROJECT TITLE</OverViewLabel>
                    <OverViewTitle>{title}</OverViewTitle>
                </OverViewWordsWrapper>
                <OverViewWordsWrapper>
                    <OverViewLabel withImage>Client</OverViewLabel>
                    <OverViewText>{client}</OverViewText>
                </OverViewWordsWrapper>
                <OverViewWordsWrapper>
                    <OverViewLabel withImage>PRODUCTION YEAR</OverViewLabel>
                    <OverViewText>{year}</OverViewText>
                </OverViewWordsWrapper>
                <OverViewWordsWrapper>
                    <OverViewLabel>OUR ROLE</OverViewLabel>
                    <OverViewText>{role}</OverViewText>
                </OverViewWordsWrapper>
            </OverViewSideDiv>
        </OverViewFlex>
    </OverViewRapper>
  )
}

export default ProjectOverview