import React, { useEffect } from 'react'
import { CardBody, CardImageWrapper, CardInfo, CardInfoAImage, CardInfoATag, CardLinkWrapper, CardTitle } from './productCardStyle'
import { FlexDiv } from '../../styled-component/MainComponent'
import { useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, x: 5, transition: { duration: 0.6, ease: "easeOut" } },
  hidden: { opacity: 0, x: "0%" }

};

const ProjectCard = ({ project }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }


  }, [controls, inView]);

  return (
    // <CardBody ref={ref} animate={controls} initial="hidden" variants={squareVariants} >
    <CardBody ref={ref} animate={controls} initial="hidden" variants={squareVariants} >
      <CardLinkWrapper to={`/portfoliodetails/${project.slug}`}>
        <CardImageWrapper imageSrc={project.mainImage} imageOld={project.blackImage}>
        </CardImageWrapper>

        <CardTitle>
        {project.name}
        </CardTitle>
      </CardLinkWrapper>

    </CardBody>
  )
}

export default ProjectCard