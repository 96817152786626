import React from 'react'
import { FlexDiv, FlexDivCenter, LoaderH3, LoaderH3Bounce, LoaderMain, LoaderP } from '../../styled-component/MainComponent'

const PageLoader = () => {
    return (
        <>
            <LoaderMain>
                <div>
                    <FlexDiv style={{gap: '1px'}}>
                        <LoaderH3>t</LoaderH3>
                        <LoaderH3Bounce>h</LoaderH3Bounce>
                        <LoaderH3>l</LoaderH3>
                        <LoaderH3Bounce>p</LoaderH3Bounce>
                        <LoaderH3>h</LoaderH3>
                        <LoaderH3Bounce>b</LoaderH3Bounce>
                        <LoaderH3>t</LoaderH3>
                    </FlexDiv>
                    <FlexDivCenter>
                        <LoaderP>Loading..... </LoaderP>
                        <LoaderP style={{ color: '#B41B00' }}>90%</LoaderP>
                    </FlexDivCenter>
                </div>
            </LoaderMain>
        </>
    )
}

export default PageLoader