import { motion } from "framer-motion";
import styled from "styled-components";
import { MainPTag } from "../../styled-component/MainComponent";
import { Link } from "react-router-dom";

export const SliderSection = styled.section`
background: linear-gradient(90.5deg, rgba(34, 34, 34, 0.9) 20.17%, rgba(34, 34, 34, 0) 99.57%);
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 800px;
position: relative;
@media (max-width: 575px) {
  height: 650px;
}
`;

export const SliderImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
`;

export const SliderWordWrapper = styled(motion.div)`
   position: absolute;
    top: 0;
    left: 0;
    width: auto;
    padding: 95px;
    @media (max-width: 575px) {
      padding: 55px 35px;
      width: auto;
    }
`;

export const SliderWordH1 = styled.h1`
font-family: 'Baguede Free';
font-style: normal;
font-weight: 400;
font-size: 64px;
line-height: 80px;
color: #FFFFFF;
mix-blend-mode: luminosity;
margin: 0;
@media (min-width: 992px) and (max-width: 1199px) {
  font-size: ${({ project }) => (project ? '55px' : '30px')};
  line-height: 54px;
}

@media (min-width: 768px) and (max-width: 991px) {
  font-size: ${({ project }) => (project ? '45px' : '30px')};
  line-height: 50px;
}

@media (min-width: 576px) and (max-width: 767px) {
  font-size: ${({ project }) => (project ? '35px' : '30px')};
  line-height: 44px;
}
@media (max-width: 575px) {
  font-size: ${({ project }) => (project ? '20px' : '30px')};
  line-height: 44px;
}
`;

export const SliderWordP = styled(MainPTag)`
font-weight: 500;
font-size: 36px;
line-height: 48px;
@media (max-width: 575px) {
  font-size: 26px;
line-height: 38px;
}
`;

export const SliderLink = styled(Link)`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 20px;
line-height: 48px;
letter-spacing: 0.01em;
color: #FFFFFF;
text-decoration: none;
display: block;
&:hover {
    color: #A1A1A1;
  }
  @media (max-width: 575px) {
    font-size: 17px;
    line-height: 36px;
  }
`;

export const SliderPTag = styled(MainPTag)`
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  text-decoration: none;
  display: block;
  margin: 0;
  &:hover {
    color: #A1A1A1;
  }
  @media (max-width: 575px) {
    font-size: 17px;
    line-height: 36px;
  }
`;