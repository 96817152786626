import React, { useState } from 'react'
import { MainDiv, TopNavLink, NavLogo, NavDiv, OpenLinksButton, NavLogoMobile, ExtendedMobileDiv, ExtentContactusP, ExtentContactImage, ExtentContactWrapper, ToggleButtonMobile } from './NavigationStyled'
import { FlexDiv } from '../../styled-component/MainComponent';
import { Link } from 'react-router-dom';
import ContactModal from '../Contact/ContactModal';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const TopNavigation = () => {
  const [extendNav, setExtendNav] = useState(false);
  const isMobile = window.innerWidth <= 575;

  const [visible, setVisible] = useState(false);
  const show = () => {
    setVisible(true);
  }
  return (
    <>
      <MainDiv>
        {
          isMobile ?
            <Link to='/'>
              <NavLogoMobile src='/images/logo.png' alt='logo' />
            </Link>
            :
            <Link to='/'>
              <NavLogo src='/images/logo.png' alt='logo' />
            </Link>
        }


        <NavDiv>
          <TopNavLink to='/' smooth="true" duration={500}>Home</TopNavLink>
          <TopNavLink to='portfolio' smooth="true" duration={500}>Portfolio</TopNavLink>
          <TopNavLink to='/event' smooth="true" duration={500}>Events</TopNavLink>
          <TopNavLink to='/services' smooth="true" duration={500}>Services</TopNavLink>
          <TopNavLink to='/about' smooth="true" duration={500}>About Us</TopNavLink>
          <TopNavLink onClick={show}>Contact us</TopNavLink>
        </NavDiv>

        <OpenLinksButton
          onClick={() => {
            setExtendNav((curr) => !curr);
          }}

        > {extendNav ? <>&#10005;</> : <> <ToggleButtonMobile src='/images/heroicons.png' alt='tobble button' /></>} </OpenLinksButton>

      </MainDiv>


      {
        extendNav && (

          <ExtendedMobileDiv
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.3,
              ease: [0, 0.71, 0.2, 1.01]
            }}
          >
            <TopNavLink to='/' onClick={() => { setExtendNav((curr) => !curr); }} smooth="true" duration={500}>Home</TopNavLink>
            <TopNavLink to='portfolio' onClick={() => { setExtendNav((curr) => !curr); }} smooth="true" duration={500}>Portfolio</TopNavLink>
            <TopNavLink to='event' onClick={() => { setExtendNav((curr) => !curr); }} smooth="true" duration={500}>Events</TopNavLink>
            <TopNavLink to='/services'  onClick={() => { setExtendNav((curr) => !curr); }} smooth="true" duration={500}>Services</TopNavLink>
            <TopNavLink to='about' onClick={() => { setExtendNav((curr) => !curr); }} smooth="true" duration={500}>About Us</TopNavLink>
            <TopNavLink onClick={() => { setExtendNav((curr) => !curr); show() }}>Contact us</TopNavLink>

            <ExtentContactWrapper>
              <ExtentContactusP>Connect with us</ExtentContactusP>
              <FlexDiv style={{ gap: '10px' }}>
                {/* <ExtentContactImage src='images/icons/facebook.png' />
                <ExtentContactImage src='images/icons/ig.png' />
                <ExtentContactImage src='images/icons/linkedin.png' />
                <ExtentContactImage src='images/icons/twitter.png' /> */}
                <a href="https://www.linkedin.com/company/thlphbt/" target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon sx={{ color: 'white', fontSize: 25, borderRadius: '50%' }} />
                </a>
                <a href="https://instagram.com/thlphbt?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon sx={{ color: 'white', fontSize: 25, borderRadius: '50%' }} />
                </a>
              </FlexDiv>

              <FlexDiv style={{ gap: '30px' }}>
                <ExtentContactusP>thlphbtdesigns@gmail.com</ExtentContactusP>
                <ExtentContactusP>+2349032583050</ExtentContactusP>
              </FlexDiv>
            </ExtentContactWrapper>

          </ExtendedMobileDiv>
        )
      }

      <ContactModal visible={visible} setVisible={setVisible} />
    </>
  )
}

export default TopNavigation