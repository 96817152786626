const services = [
    {
      id: 'service1',
      title: 'Production Designer',
      des: 'Responsible for the overall visual style and visual storytelling, including creating mood boards, lookbooks, 3D designs, and models, for setting the look, tone, mood, and feel of every production by Transforming concepts into tangible, visually stunning realities, our production designer leads with a keen eye for detail and an overarching vision for your project.',
    },
    {
      id: 'service2',
      title: 'Art Director',
      des: 'Our art directors lead the art department by monitoring the strict and well-planned execution of agreed concept and designs by the production designer and director as the case may be, he is supported by our assistant art director and standby art directors who keeps a keen eye on the project at all time, he brings creativity and strategic insight to ensure a cohesive and visually impactful experience.',
    },
    {
      id: 'service3',
      title: 'Property Master',
      des: 'From conceptualization to creation, our props makers bring unique and authentic elements to your project, especially elements tailored to specifics as the clients or director wants.',
    },
    {
      id: 'service4',
      title: 'Set Designer',
      des: 'Combining form and function, our set designers translate ideas into physical spaces that enhance the narrative, no design is too complex and impossible to create.',
    },
    {
      id: 'service5',
      title: 'Set Decorator',
      des: 'Adding the finishing touches, our s curate details that elevate the visual appeal of your project without mincing creativity and style.',
    },
    {
      id: 'service6',
      title: 'Set Dresser',
      des: 'Meticulously arranging and organizing set elements, our set dressers ensure every detail contributes to the overall aesthetics without straying away from the mood board and visual style guide.',
    },
    {
      id: 'service7',
      title: 'Art Runners',
      des: 'Keeping the production moving smoothly, our art runners assist in various tasks, ensuring seamless coordination within the art department',
    },
    {
      id: 'service8',
      title: 'Buyers',
      des: 'Our buyers have an eye for quality, sourcing materials and props that align with the creative vision of your project',
    },
    {
      id: 'service9',
      title: 'Concept Artist (Illustrator)',
      des: 'Transforming ideas into visuals, our concept artists provide the initial glimpses into the artistic direction of the project',
    },
    {
      id: 'service10',
      title: '3D Designer',
      des: 'Utilizing cutting-edge technology, our 3D designers bring concepts to life, offering a preview of the final product, based on preferences, actual models can be made.',
    },
    {
      id: 'service11',
      title: 'Graphic Artist',
      des: 'Crafting visually striking graphics, our graphic artists contribute to the overall aesthetic and branding of your project.',
    },
    {
      id: 'service12',
      title: 'Storyboard Artist',
      des: 'Visualizing the narrative, our storyboard artists map out the sequential flow of your project.',
    },
    {
      id: 'service13',
      title: 'Art Department Coordinator',
      des: 'The backbone of our operations, the coordinator ensures seamless communication and coordination within the art department',
    },
    {
      id: 'service14',
      title: 'Carpenters and furniture makers',
      des: 'Our head carpenter ensures the construction of sets with precision and expertise, our carpenters bring designs to life, constructing the physical spaces that set the stage for your production and our furniture makers craft bespoke furniture pieces that enhance the authenticity of your set.',
    },
    {
      id: 'service15',
      title: 'Welder',
      des: 'Adding a touch of metalwork expertise to create unique and durable set pieces.',
    },
    {
      id: 'service16',
      title: 'Scenic Artist',
      des: 'Transforming surfaces with intricate details, our scenic artists add texture and depth to your production.',
    },
    {
      id: 'service17',
      title: 'SFX (Special Effects)',
      des: 'Creating visually stunning and immersive effects that captivate audiences.',
    },
    {
      id: 'service18',
      title: 'Painter',
      des: 'Bringing color and life to your set, our painters ensure every detail is visually striking.',
    },
    {
      id: 'service19',
      title: 'Greensmen',
      des: 'Adding natural elements to your set, our greensmen create authentic and vibrant environments.',
    },
    {
      id: 'service20',
      title: 'Animal Wranglers',
      des: 'Ensuring the safe and humane handling of animals on set adds a touch of realism to your production.',
    },
    {
      id: 'service21',
      title: 'Weapon Master',
      des: 'Managing and coordinating the use of weapons on set with expertise and precision',
    },
    {
      id: 'service22',
      title: 'Printers',
      des: 'Producing high-quality graphics and prints for set decoration and branding',
    },
    {
      id: 'service23',
      title: 'Branding Experts',
      des: 'Event and Production and souvenir  branding and designs, we are experts in the use of banners, SAv, mersh and other printing materials',
    }

    
  ];
  
  export default services;
  