import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainDiv = styled.div`
display: flex;
justify-content: space-around;
height: 80px;
background: #222222;
// background: transparent;
backdrop-filter: blur(100px);
align-items: center;
z-index: 50;
position: sticky;
top: 0;

@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) and (max-width: 991px) {

}

@media (min-width: 576px) and (max-width: 767px) {
  justify-content: space-evenly; 
}

@media (max-width: 575px) {
  justify-content: space-between;
  padding: 10px;
  position: inherit;
  height: 50px;
}
`;

export const NavLogo = styled.img`
height: 47.28557205200195px;
width: 153.0418701171875px;
@media (max-width: 575px) {
  display: none;
}
@media (min-width: 992px) and (max-width: 1199px) {
  width: 140.0418701171875px;
}

@media (min-width: 768px) and (max-width: 991px) {
  width: 105.0418701171875px;
  height: 37.28557205200195px;
}

@media (min-width: 576px) and (max-width: 767px) {
  width: 100.0418701171875px;
  height: 37.28557205200195px;
}
`;

export const NavLogoMobile = styled.img`
height: 31.523714065551758px;
width: 102.02790832519531px;
padding-left: 25px;
  @media (min-width: 575px) {
    display: none;
  }
`;
export const ToggleButtonMobile = styled.img`
width: 28px;
height: 28px;

`;

export const NavDiv =  styled.div`
display: flex;
justify-content: center;
width: 60%;
gap: 50px;
@media (max-width: 575px) {
    display: none;
}
@media (min-width: 992px) and (max-width: 1199px) {
  gap: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
  gap: 18px;
}

@media (min-width: 576px) and (max-width: 767px) {
  gap: 15px;
}

`;

export const TopNavLink = styled(Link)`
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
color: #FFFFFF;
text-decoration: none;
&:hover {
  color: #A1A1A1;
}
@media (max-width: 575px) {
    font-size: 20px;
    line-height: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  font-size: 16px;
}

@media (min-width: 768px) and (max-width: 991px) {
  font-size: 14px;
}

@media (min-width: 576px) and (max-width: 767px) {
  font-size: 12px;
}

`;

export const OpenLinksButton = styled(motion.button)`
display: none;  
width: 70px;
  height: 50px;
  background: none;
  border: none;
  color: white;
  font-size: 45px;
  cursor: pointer;
  @media (max-width: 575px) {
    display: block;
  }
`;


export const ExtendedMobileDiv = styled(motion.div)`
flex-direction: column;
align-items: flex-start;
padding: 40px 10px;
gap: 16px;
background: #222222;
display: none;
height: 452px;
@media (max-width: 575px) {
  display: flex;
}
`;

export const ExtentContactusP = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.01em;
color: #F0F0F0;
`; 
export const ExtentContactImage = styled.img`
width: 24px;
height: 24px;
`; 

export const ExtentContactWrapper = styled.div`
margin-top: 5rem;
`;

