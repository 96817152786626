import styled from "styled-components";
import { MainPTag } from "../../styled-component/MainComponent";
import { Link } from "react-router-dom";

export const BannerWrapper = styled.section`
width: 100%;
height: 450px;
background: linear-gradient(180.21deg, #000000 0.18%, rgba(0, 0, 0, 0) 0.18%, #000000 99.82%), url("/images/porfolio_banner.png");
// background-image: url("/images/porfolio_banner.png");
display:flex;
justify-content: center;
align-items: center;
@media (min-width: 992px) and (max-width: 1199px) {
  
}

@media (min-width: 768px) and (max-width: 991px) {
  
}

@media (min-width: 576px) and (max-width: 767px) {
  
}
@media (max-width: 575px) {
  height: 250px;
}
`;

export const SortWrapper = styled.div`
display: flex;
justify-content: space-between;
padding: 2rem 8rem;
height: 30px;
@media (min-width: 992px) and (max-width: 1199px) {
  padding: 2rem 5rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  padding: 2rem 3rem;
}

@media (min-width: 576px) and (max-width: 767px) {
  padding: 2rem 3rem;
}
@media (max-width: 575px) {
  padding: 1rem 0.5rem;
  flex-direction: row-reverse;
}
`;

export const SortPTag = styled(MainPTag)`
line-height: 36px;
letter-spacing: 0.01em;
color: #272727;
margin: 0;
@media (max-width: 575px) {
  display: none;
}
`;

export const SearchWrapper = styled.div`
position: relative;
width: 70%;
`;

export const SortIcon = styled.img`
`;

export const SearchInput = styled.input`
  padding: 15px 18px;
  width: 728px;
  border: 1px solid #777777;
  border-radius: 20px;
  padding-left: 3rem;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-color: #777777;
  color: #777777;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  &:focus {
    outline: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }
  &::placeholder {
    
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 600px;
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    width: 428px;
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    width: 300px;
  }

  @media (max-width: 575px) {
    width: 228px;
    padding: 12px 15px;
    padding-left: 3rem;
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  top: 80%;
  left: 1rem;
  transform: translateY(-50%);
  color: #777777;
  font-size: 2rem;
  @media (max-width: 575px) {
    top: 70%;
  }
`;

export const PortfolioBreadCrumbWrap = styled.div`
padding: 0rem 8rem;
@media (min-width: 992px) and (max-width: 1199px) {
  padding: 0rem 5rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  padding: 0rem 3rem;
}

@media (min-width: 576px) and (max-width: 767px) {
  padding: 0rem 3rem;
}
@media (max-width: 575px) {
  padding: 0rem 0.5rem;
}
`;
export const PortfolioBreadFirstP = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
color: #272727;
`;
export const PortfolioBreadSecondP = styled(PortfolioBreadFirstP)`
color: #B41B00;
`;

export const PortfolioListWrapper = styled.section`
padding: 1rem 8rem;

@media (min-width: 992px) and (max-width: 1199px) {
  padding: 1rem 5rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  padding: 1rem 3rem;
}

@media (min-width: 576px) and (max-width: 767px) {
  padding: 1rem 3rem;
}
@media (max-width: 575px) {
  padding: 0rem 0.5rem;
}
`;

export const SortDescription = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.01em;
color: #777777;
`;
export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  display: block;

`;

export const RadioLabel = styled.label`
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #2E2E2E;

`;

export const RadioCheck = styled.div`
  display: block;
  position: relative;
  border: 5px solid ${({ checked }) => (checked ? '#B41B00' : 'black')} ;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
  border-color: ${({ checked }) => (checked ? '#B41B00' : 'black')};
`;

export const RadioCheckInside = styled.div`
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 2.5px;
  left: 2.5px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  background: ${({ checked }) => (checked ? '#B41B00' : '')};
`;

export const PortfolioButton = styled.button`
  border-radius: 20px;
  border: transparent;
  background: ${({ outline }) => (outline ? 'transparent' : '#B41B00')};
  width: 150px;
  height: 50px;
  color: ${({ outline }) => (outline ? '#272727' : '#FFFFFF')};
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  cursor: pointer;
  @media (max-width: 575px) {
    font-size: 15px;
    line-height: 26px;
    width: 130px;
    height: 45px;
  }
`;

export const PortfolioContactP = styled.p`
  font-family: 'Baguede Free';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 80px;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
  @media (min-width: 992px) and (max-width: 1199px) {
    
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: 575px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const PortFolioContactUsLink = styled(Link)`
background: #B41B00;
border-radius: 20px;
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
color: #FFFFFF;
text-decoration: none;
padding: 10px;
`;

export const OpenFilterWrapper = styled.div`
width: 30%;
margin-bottom: auto;
@media (max-width: 575px) {
  display: none;
}
`;