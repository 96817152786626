import React from 'react'
import { FooterCopyRight, FooterDiv, FooterFlex, FooterLink, FooterLinkEmail, FooterLogo, FooterMobileView, FooterTitle, FooterWrapper } from './FooterStyle'
import { FlexDiv, MainPTag, SectionBody } from '../../styled-component/MainComponent'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Grid } from '@mui/material';
const Footer = () => {
    return (
        <FooterWrapper>
            <SectionBody>
                <FooterMobileView>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Link to='/'>
                                <FooterLogo src='/images/logo.png' />
                            </Link>

                        </Grid>
                        <Grid item xs={5}>
                            <FooterTitle>Company</FooterTitle>
                            <FooterLink to='portfolio'>Portfolio</FooterLink>
                            <FooterLink to='about'>About us</FooterLink>
                            <FooterLink to='event'>Event</FooterLink>
                        </Grid>
                        <Grid item xs={5}>
                            <FooterTitle>Contact us</FooterTitle>
                            <FooterLinkEmail to="mailto:thlphbtdesigns@gmail.com">thlphbtdesigns@gmail.com</FooterLinkEmail>
                            <MainPTag>+2349032583050</MainPTag>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <MainPTag>Lagos, Nigeria</MainPTag>
                        </Grid>
                        <Grid item xs={12}>
                            <FooterTitle>connect with us</FooterTitle>
                            <FlexDiv style={{ gap: '1rem' }}>
                                {/* <Link to={"https://www.linkedin.com/company/thlphbt/"}><LinkedInIcon sx={{ color: 'white', fontSize: 20, borderRadius: '50%' }} /></Link> */}
                                <a href="https://www.linkedin.com/company/thlphbt/" target="_blank" rel="noopener noreferrer">
                                    <LinkedInIcon sx={{ color: 'white', fontSize: 20, borderRadius: '50%' }} />
                                </a>
                                <a href="https://instagram.com/thlphbt?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                                    <InstagramIcon sx={{ color: 'white', fontSize: 20, borderRadius: '50%' }} />
                                </a>
                                {/* <Link to={"https://instagram.com/thlphbt?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr"}><InstagramIcon sx={{ color: 'white', fontSize: 20, borderRadius: '50%' }} /></Link> */}
                            </FlexDiv>
                        </Grid>
                    </Grid>
                </FooterMobileView>

                <FooterFlex>

                    <FooterDiv>
                        <Link to='/'>
                            <FooterLogo src='/images/logo.png' />
                        </Link>
                        <MainPTag>Lagos, Nigeria <br /> </MainPTag>
                    </FooterDiv>
                    <FooterDiv>
                        <FooterTitle>Company</FooterTitle>
                        <FooterLink to='portfolio'>Portfolio</FooterLink>
                        <FooterLink>About us</FooterLink>
                        <FooterLink>Event</FooterLink>
                    </FooterDiv>
                    <FooterDiv>
                        <FooterTitle>Contact us</FooterTitle>
                        <FooterLinkEmail to="mailto:thlphbtdesigns@gmail.com">thlphbtdesigns@gmail.com</FooterLinkEmail>
                        <MainPTag>+2349032583050</MainPTag>
                    </FooterDiv>
                    <FooterDiv>
                        <FooterTitle>connect with us</FooterTitle>
                        <FlexDiv style={{ gap: '1rem' }}>
                            <a href="https://www.linkedin.com/company/thlphbt/" target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon sx={{ color: 'white', fontSize: 40, borderRadius: '50%' }} />
                            </a>
                            <a href="https://instagram.com/thlphbt?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon sx={{ color: 'white', fontSize: 40, borderRadius: '50%' }} />
                            </a>
                            {/* <Link><LinkedInIcon sx={{ color: 'white', fontSize: 40, borderRadius: '50%' }} /></Link>
                            <Link><InstagramIcon sx={{ color: 'white', fontSize: 40, borderRadius: '50%' }} /></Link> */}
                        </FlexDiv>
                    </FooterDiv>
                </FooterFlex>
                <FooterCopyRight>© 2023 All right reserved. thlphbt</FooterCopyRight>
            </SectionBody>
        </FooterWrapper>
    )
}

export default Footer