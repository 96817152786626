import React, { useState, useEffect } from 'react'
import { useMediaQuery } from '@mui/material';
import VideoComponent from '../../Components/Projects/TopComponent/VideoComponent'
import ProjectOverview from '../../Components/Projects/Overview/ProjectOverview'
import ProjectSlider from '../../Components/Projects/Slider/ProjectSlider'
import ProjectContent from '../../Components/Projects/Content/ProjectContent'
import SimilarProject from '../../Components/Projects/SimilarProject/SimilarProject'
import SketchedContent from '../../Components/Projects/Content/SketchedContent'
import { projects } from '../../Utils/project'

const FullPortfolioDetails = ({ portfolio }) => {
    const [similar, setSimilar] = useState([]);
    const isMobile = useMediaQuery('(max-width:575px)');
    // Check if the portfolio object exists and has the expected properties 
    const hasSlider = portfolio?.slider?.length > 0;
    const hasSetDesignSketche = portfolio?.setDesignSketche?.length > 0;
    const hasBehindTheScene = portfolio?.behindTheScene?.length > 0;
    const OtherVideo = portfolio?.video_l;

    // Function to shuffle the array using Fisher-Yates algorithm 
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };


    useEffect(() => {
        const getSimilarProjects = (currentProjectId) => {
            const filteredProjects = projects.filter((project) => project.id !== currentProjectId);

            const shuffledProjects = shuffleArray(filteredProjects);
            if (isMobile) {
                return shuffledProjects.slice(0, 2);
            }else{

                return shuffledProjects.slice(0, 3);
            }
        };

        const currentProjectId = portfolio?.id;
        if (currentProjectId) {
            setSimilar(getSimilarProjects(currentProjectId));
        }
    }, [portfolio])
    return (
        <>
            <VideoComponent video={portfolio?.video_link} />
            <ProjectOverview withImage={!!portfolio?.mainImage} title={portfolio?.name} image={portfolio?.mainImage} client={portfolio?.client} year={portfolio?.production_year} role={portfolio?.role} />

            <div style={{ marginTop: '40px' }}>
                {OtherVideo && <VideoComponent video={portfolio?.video_l} />}
            </div>


            {hasSlider > 0 && <ProjectSlider images={portfolio.slider} />}

            {hasSetDesignSketche > 0 && <SketchedContent title={'SET DESIGN  SKETCHES'} sketchedImage={portfolio?.setDesignSketche} />}

            {hasBehindTheScene > 0 && <ProjectContent title={'BEHIND THE SCENES'} bts={portfolio?.behindTheScene} />}

            <SimilarProject similar={similar} />
        </>
    )
}

export default FullPortfolioDetails