import styled from "styled-components";
import { MainHeaderTag } from "../../../styled-component/MainComponent";

export const OverViewRapper = styled.section`
    padding: 5rem 7rem;
    @media (min-width: 992px) and (max-width: 1199px) {
        padding: 3rem 5rem;
      }
      
      @media (min-width: 768px) and (max-width: 991px) {
        padding: 2rem 3rem;
      }
      
      @media (min-width: 576px) and (max-width: 767px) {
        padding: 2rem 3rem;
      }
    @media screen and (max-width: 575px) {
        padding: 0.5rem 0.5rem;
    }
`;

export const OverTitle = styled(MainHeaderTag)`
text-align: center;
@media (max-width:575px) {
    // margin: auto;
    // width: 70%;
    // margin-bottom: 2rem;
    }
`;

export const OverViewFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 2rem;
    height: 400px;
    @media (min-width: 992px) and (max-width: 1199px) {
        
      }
      
      @media (min-width: 768px) and (max-width: 991px) {
        
      }
      
      @media (min-width: 576px) and (max-width: 767px) {
        
      }
    @media screen and (max-width: 575px) {
        flex-direction: column;
        height: 1000px;
    }
`;
export const OverViewSideImage = styled.img`
    width: 30%;
    object-fit: cover;
    height: 100%;
    @media screen and (max-width: 575px) {
        width: 98%;
    }
`;
export const OverViewSideDiv = styled.div`
    width: 100%;
    height: 100%;
`;
export const OverViewLabel = styled.p`
    color: #000;
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0;
    margin-top: ${({ withImage }) =>
    withImage ? "10px" : "20px"};
`;
export const OverViewTitle = styled.p`
    color: #000;
    font-family: Circular Std;
    font-size: 40px;
    font-style: normal;
    font-weight: 450;
    line-height: 48px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    margin: 0;
    margin-top: 15px;
`;
export const OverViewText = styled.p`
    color: #000;
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 30px;
    letter-spacing: 0.2px;
    margin: 0;
    margin-top: 15px;
`;
export const OverViewWordsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #000;
`;

