import styled from "styled-components";
import { MainButton } from "../../styled-component/MainComponent";

export const ContactMains = styled.div`
width: 50%;
@media (max-width: 998px) {
    width: 60%;
  }
@media (max-width: 575px) {
    width: 100%;
  }
`;

export const ContactWord = styled.p`
font-family: 'Baguede Free';
font-style: normal;
font-weight: 400;
font-size: 64px;
line-height: 80px;
color: #272727;
margin-bottom: 0px;
@media (max-width: 998px) {
    font-size: 44px;
    line-height: 70px;
  }
@media (max-width: 575px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    display: none;
    margin-top: 40px;
  }
`;
export const ContactWordRed = styled(ContactWord)`
color: #B41B00;
margin: 0;
@media (max-width: 575px) {
   display: none;
  }
`;
export const ContactWordMobile= styled(ContactWord)`
display: none;
@media (max-width: 575px) {
   display: block;
  }
`;
export const ContactWordRedMobile = styled(ContactWord)`
display: none;
color: #B41B00;
margin: 0;
@media (max-width: 575px) {
   display: block;
  }
`;

export const ContactWords2 = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.01em;
color: #000000;
@media (max-width: 575px) {
    display: none;
   }
`;

export const ContactLabel = styled.label`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.01em;
color: #272727;
@media (max-width: 550px) {
  font-size:  ${({ modal }) => (modal ? '16px' : '20px')};
  line-height:  ${({ modal }) => (modal ? '20px' : '30px')};
}
`;

export const ContactInput = styled.input`
background: #FCFCFC;
border: 1px solid #A1A1A1;
border-radius: 12px;
width: 95%;
height: 48px;
padding-left: 1rem;
&:focus {
  border: 2px solid #B41B00;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  outline: none;
}
@media (max-width: 550px) {
  width: ${({ modal }) => (modal ? '80%' : '95%')};
  height:  ${({ modal }) => (modal ? '38px' : '48px')};
}
`;
export const ContactTextArea = styled.textarea`
background: #FCFCFC;
border: 1px solid #A1A1A1;
border-radius: 12px;
width: 95%;
height:  ${({ modal }) => (modal ? '102px' : '152px')};
padding-left: 1rem;
padding-top: 1rem;
&:focus {
  border: 2px solid #B41B00;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  outline: none;
}
@media (max-width: 550px) {
  height:  ${({ modal }) => (modal ? '72px' : '152px')};
  width:  ${({ modal }) => (modal ? '80%' : '95%')};
}
`;

export const ContactInputForm = styled.form`
display: flex;
flex-direction: column;
width: ${({ modal }) => (modal ? '100%' : '80% ')};
padding-left:   ${({ modal }) => (modal ? '0px' : '100px ')};
margin-top:  ${({ modal }) => (modal ? '0rem' : '4rem ')};
@media (max-width: 998px) {
    padding-left:  ${({ modal }) => (modal ? '0px' : '5px ')};
    margin-top:  ${({ modal }) => (modal ? '0rem' : '2rem ')};
    width: 100%;
}
@media (max-width: 550px) {
    padding-left: 5px;
    margin-top: 0rem;
    width: 100%;
}
`;
export const ContactInputDiv = styled.div`
padding: 0.5rem;
// width:  100%;
@media (max-width: 550px) {
    padding:  ${({ modal }) => (modal ? '0ren' : '0.8rem')};
}
`;
export const ContactButtonDiv = styled.div`
padding-top:  ${({ modal }) => (modal ? '1ren' : '2rem')};
@media (max-width: 550px) {
    padding-top: 1rem;
    display: flex;
    justify-content: end;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
`;
export const ContactButton = styled(MainButton)`
width: ${({ modal }) => (modal ? '35%' : '95%')};
@media (max-width: 550px) {
    width: 35%;
    height: 48px;
    padding: 0.1em;
}
`;

export const ModalContactWord = styled.p`
  font-family: Circular Std;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px; 
  color:  ${({ red }) => (red ? '#B41B00' : '#272727')};
  margin: 0;
  @media (max-width: 550px) {
    font-size: 18px;
    line-height: 34px; 
  }
`;

export const ContactDivSpace = styled.div`
display: flex;
justify-content: space-between;
@media (max-width: 575px) {
  flex-direction: column;
  }
`;


export const  SpinnerDiv =  styled.div`
display: flex;
justify-content: center;
`
export const  SucessMessageCon =  styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.01em;
text-align: center;
color: #198754;
margin: 0;
`
export const  ErrorMessageCon =  styled(SucessMessageCon)`
color: #FF9494;
`