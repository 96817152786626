import React from 'react'
import { TopRapper } from './Topstyles'
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css'
import '../../../App.css'
const VideoComponent = ({ video, outline }) => {
  const key = video || 'default';
  return (
    <TopRapper>
  
      {video && (
        <Video loop className='video-styles' key={key}>
          <source src={video} type="video/mp4" />
        </Video>
      )}
    </TopRapper>

  )
}

export default VideoComponent