import styled from "styled-components";

export const ProjectSliderWrapper = styled.section`
width: 100%;
height: 600px;
position: relative;
margin-top: 50px;
@media (min-width: 992px) and (max-width: 1199px) {
  
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    height: 500px;
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    
  }
  @media (max-width: 575px) {
    height: 500px;
    margin-top: 140px;
  }
`;

export const ProjectSliderImg = styled.img`
width: 100%;
height: 600px;
object-fit: cover;
@media (min-width: 992px) and (max-width: 1199px) {
  
}

@media (min-width: 768px) and (max-width: 991px) {
    height: 500px;
}

@media (min-width: 576px) and (max-width: 767px) {
  
}
@media (max-width: 575px) {
    height: 500px;
}
`;

export const ProjectSliderIconDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: absolute;
gap: 5px;
bottom: 3%;
left: 50%;
`;
export const ProjectSliderIconDiv2 = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 5px;
position: absolute;
left: 35%;
bottom: 8%;
`;

export const ProjectSliderIcon = styled.div`
width: 8px;
height: 8px;
border-radius: 50%;
background-color: ${({ activeRev }) => (activeRev === 'true' ? "#B41B00" : "#FFFFFF")};

border:  ${({ activeRev }) => (activeRev === 'true' ? "3px solid #B41B00" : "3px solid #FFFFFF")};

`;


export const ForwardWapper = styled.button`
border: 1px solid #B41B00;
background-color: white;
cursor: pointer;
border-radius: 30%;
position: absolute;
top: 50%;
right: 1%;
`;

export const BackwardWapper = styled.button`
border: 1px solid #B41B00;
background-color: white;
cursor: pointer;
border-radius: 30%;
position: absolute;
top: 50%;
left: 1%;
`;
