import styled from "styled-components";
import { BannerWrapper } from "../Portfolio/portfolio.style";

export const AboutBannerWrapper = styled(BannerWrapper)`
    background: linear-gradient(180.21deg, #000000 0.18%, rgba(0, 0, 0, 0) 0.18%, #000000 99.82%), url("/images/aboutbg.png");
    display: inline-block;
    @media (max-width: 575px) {
        background: linear-gradient(180.21deg, #000000 0.18%, rgba(0, 0, 0, 0) 0.18%, #000000 99.82%), url("/images/aboutmobilebg.png");
        height: 575px;
      }
`;

export const ServiceBannerWrapper = styled(BannerWrapper)`
    background: linear-gradient(180.21deg, #000000 0.18%, rgba(0, 0, 0, 0) 0.18%, #000000 99.82%), url("/images/service.png");
    display: inline-block;
    @media (max-width: 575px) {
        background: linear-gradient(180.21deg, #000000 0.18%, rgba(0, 0, 0, 0) 0.18%, #000000 99.82%), url("/images/mobileser.png");
        height: 575px;
      }
`;

export const AboutBannerTitle = styled.h1`
    font-family: 'Baguede Free';
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px; 
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 998px) {
        font-size: 44px;
        line-height: 70px;
      }
    @media (max-width: 575px) {
        margin-top: 40px;
      }
`;

export const ServiceBannnerFlex = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    @media (max-width: 575px) {
        margin-top: 10px;
        flex-direction: column;
    }
`;
export const ServiceBannerTitle = styled.h1`
    font-family: 'Baguede Free';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px; 
    color: #FFFFFF;
    text-align: center;
    width: 40%;
    padding: 20px;
    @media (max-width: 998px) {
        font-size: 44px;
        line-height: 70px;
      }
    @media (max-width: 575px) {
        margin-top: 25px;
        width: 90%;
        font-size: 35px;
        line-height: 30px; 
        padding: 20px;
      }
`;
export const AboutBannerSubTitle = styled.p`
    color: #FFF;
    text-align: center;
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 30px; /* 150% */
    letter-spacing: 0.2px;
    margin: 0;
    width: 60%;
    @media (max-width: 998px) {
        
      }
    @media (max-width: 575px) {
        font-size: 16px;
        width: 80%;
      }
`;

export const ServiceSecondDiv = styled.div`
      width: 40%;
      margin-top: 60px;
      @media (max-width: 575px) {
        width: 100%;
      }
`;
export const ServiceTalkLaterDiv = styled.div`
      margin-top: 60px;
`;
export const ServiceBannerSubTitle = styled.p`
    color: #FFF;
    text-align: start;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 30px; /* 150% */
    letter-spacing: 0.2px;
    margin: 0;
    @media (max-width: 998px) {
        
      }
    @media (max-width: 575px) {
        font-size: 16px;
        text-align: center;
      }
`;
export const ServiceAccordionTitle = styled.p`
    color: #272727;
    text-align: start;
    font-family: Circular Std;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 150% */
    letter-spacing: 0.2px;
    margin: 0;
    @media (max-width: 998px) {
        
      }
    @media (max-width: 575px) {
        width: 80%;
      }
`;
export const ServiceAccordionPTage = styled.p`
    color: #8E8E8E;
    text-align: start;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 150% */
    letter-spacing: 0.2px;
    margin: 0;
    @media (max-width: 998px) {
        
      }
    @media (max-width: 575px) {
        font-size: 14px;
      }
`;
export const ServiceTalkPTage = styled.p`
    color: #8E8E8E;
    text-align: center;
    font-family: Circular Std;
    font-size: 40px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px; /* 150% */
    letter-spacing: 0.2px;
    margin: 0;
    @media (max-width: 998px) {
        
      }
    @media (max-width: 575px) {
        font-size: 20px;
      }
`;

export const ServiceBannerButtonn = styled.button`
border-radius: 15px;
border: transparent;
background: #B41B00;
width:  '150px';
height: 50px;
color: #FFF;
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
cursor: pointer;
padding: 5px 20px;
margin-top: 20px;
@media (max-width: 575px) {
    margin-top: 40px;
    margin-left: 90px;
  }
`;

export const AboutPageFlex = styled.section`
        display: flex;
        justify-content: space-between;
        gap: 2rem;
    @media (min-width: 992px) and (max-width: 1199px) {

    }

    @media (min-width: 768px) and (max-width: 991px) {
        gap: 2rem;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        gap: 1rem;
    }
    @media (max-width: 575px) {
        flex-direction: column;
        gap: 2rem;
    }
`;
export const AboutPageFlex2 = styled.section`
        display: flex;
        justify-content: space-between;
        gap: 2rem;
    @media (min-width: 992px) and (max-width: 1199px) {

    }

    @media (min-width: 768px) and (max-width: 991px) {
        gap: 2rem;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        gap: 1rem;
    }
    @media (max-width: 575px) {
        gap: 0.5rem;
    }
`;

export const AboutTitle = styled(AboutBannerTitle)`
    color: #272727;
    margin-top: 0;
    margin-bottom: 15px;
    text-align: ${({ grey }) => (grey ? 'center' : 'start')};
    position: relative;
    font-size: 48px;
    line-height: 50px; 
    @media (max-width: 575px) {
        margin-top: 40px;
        font-size: 36px;
        line-height: 44px;
      }
`;
export const AboutWord = styled(AboutBannerSubTitle)`
    color:  ${({ grey }) => (grey ? '#777' : '#272727')};
    text-align:  ${({ grey }) => (grey ? 'justify' : 'start')};
    width: 100%;
    margin-top: 0;
    font-size: 15px;
    @media (max-width: 575px) {
        font-size: 16px;
        line-height: 24px; /* 150% */
        letter-spacing: 0.16px;
      }
`;
export const AboutDivWrap = styled.div`
    width: 50%;
    @media (max-width: 575px) {
        width: 100%;
      }
`;
export const AboutImage = styled.img`
    width: 90%;
`;

export const AboutImage2 = styled.img`
    width: 90%;
    height: 86%;
`;

export const AboutFavicon = styled.img`
    width: 309.559px;
    height: 71.785px;
    flex-shrink: 0;
    position: absolute;
    top: -20px;
    left: 0px;
    @media (max-width: 575px) {
        width: 225.559px;
        height: 61.785px;
        left: -5px;
        bottom: 20px;
    }
`;
export const AboutFavicon2 = styled.img`
    width: 309.559px;
    height: 71.785px;
    flex-shrink: 0;
    position: absolute;
    top: -10px;
    left: 70px;
    @media (max-width: 575px) {
        width: 225.559px;
        height: 61.785px;
        left: 60px;
    }
`;

export const AboutSliderWord = styled.p`
    color: ${({ red }) => (red ? '#B41B00' : '#8E8E8E')}; 
    font-family: Baguede Free;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 120px;
    margin: 0;
    @media (max-width: 575px) {
        font-size: 23.5px;
        line-height: 39px;
    }
`;