import React, {useState} from 'react'
import AboutBanner from '../Components/Banner/AboutBanner'
import { FlexDivCenter, MainButtonLink, SectionBody } from '../styled-component/MainComponent'
import { AboutDivWrap, AboutFavicon, AboutFavicon2, AboutImage, AboutImage2, AboutPageFlex, AboutPageFlex2, AboutSliderWord, AboutTitle, AboutWord } from '../Components/Banner/banner'
import { BannerWrapper, SlideTrack, Slider } from '../styled-component/BannerStyled'
import ContactModal from '../Components/Contact/ContactModal'

const About = () => {

  const [visible, setVisible] = useState(false);
  const show = () => {
      setVisible(true);
  }
    return (
        <>
            <AboutBanner />
            <SectionBody>
                <AboutPageFlex>
                    <AboutDivWrap>
                        <AboutTitle>
                            WE BREATHE CREATIVITY
                            <AboutFavicon src='images/circle.png' />
                        </AboutTitle>
                        <AboutWord>
                            Due to the diversity and versatility of our designs, we have been called great at developing and maintaining a creative vision that speaks to viewers' artist minds. Our work cuts across diverse genres of motion pictures. We have worked on feature-length films, short films, music videos, commercials, event design, stop-motion films, TV shows, 3D designs, silent films, documentaries, fashion shoots and campaigns, interior and exterior designs for companies, private homes, serviced apartments, and many more.
                        </AboutWord>
                        <br />
                        <AboutWord>
                            Over the years, we have made it a culture to understand our clients' needs and go to whatever length is required to actualize a project as envisioned by our clients, while taking into account production costs and maximizing our marketing knowledge.

                        </AboutWord>
                    </AboutDivWrap>
                    <AboutDivWrap>
                        <AboutImage src='images/aboutnew2.gif' />
                    </AboutDivWrap>

                </AboutPageFlex>
            </SectionBody>
            <SectionBody>
                <AboutPageFlex>
                    <AboutDivWrap>
                        <AboutImage src='images/aboutnew1.gif' />
                    </AboutDivWrap>
                    <AboutDivWrap>
                        <AboutTitle>
                            WE INTERSECT
                            <AboutFavicon2 src='images/circle.png' />
                        </AboutTitle>
                        <AboutWord>
                            THLPHBT provides two related services under "BIGTURES" and “PROPSSTORE”
                        </AboutWord>
                        <ul>
                            <li>
                                <AboutWord>
                                    BIGTURES provides customized furniture for homes, offices, serviced apartments, and hotels, among others. Our services here also include supplying furniture and redesigning and redecorating any space for large, medium, and small-scale organizations; this is a more creative form of interior and exterior design.
                                </AboutWord>
                            </li>
                            <li>
                                <AboutWord>
                                    PROPSSTORE provides film, TV props and set elements. We also offer rental and sale services, as well as building special props on request.
                                </AboutWord>
                            </li>
                        </ul>

                    </AboutDivWrap>


                </AboutPageFlex>
            </SectionBody>
            <SectionBody>
                <AboutTitle grey>
                    OUR MISSION
                </AboutTitle>
                <AboutWord grey>
                    At THLPHBT, we aim to create and convey messages through design in an attractive and memorable way that keeps any audience captivated. We are always passionate about learning, relearning, and unlearning, simply because a client’s expectations must always be met and surpassed. Our client’s vision guides us to come up with creative concepts not just for films and motion pictures but for corporate environments, hotels, and service apartments.

                </AboutWord>
            </SectionBody>

            <BannerWrapper>
                <Slider>
                    <SlideTrack>
                        <AboutSliderWord>creativity</AboutSliderWord>
                        <AboutSliderWord>. </AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord >creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord >creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>excellence</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord >creativity</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                        <AboutSliderWord red>perfection</AboutSliderWord>
                        <AboutSliderWord>.</AboutSliderWord>
                    </SlideTrack>
                </Slider>
            </BannerWrapper>

            <SectionBody>
                <AboutTitle grey>
                    MEET THE TEAM
                </AboutTitle>
                <AboutWord grey>
                    Our team is made up of creatives with diverse filmmaking backgrounds and skill sets, as well as strong multi-tasking mindsets. We have grown to have very critical analytical skills, a strong adaptivity culture, and a keen leadership orientation while working as a happy team. We have a complete team of art directors, set designers, property masters, set decorators, set dressers, art runners, greensmen, concept artists (illustrators), storyboard artists, graphic artists, 3D artists, makeup artists, costume designers, animal wranglers, and the construction department (carpenters, electricians, painters, and craft men).
                </AboutWord>
                <AboutPageFlex2 style={{ marginTop: '25px' }}>
                    <AboutDivWrap>
                        <AboutImage src='images/about4.png' />
                    </AboutDivWrap>
                    <AboutDivWrap>
                        <AboutImage2 src='images/about3.png' />
                    </AboutDivWrap>
                </AboutPageFlex2>
            </SectionBody>

            <SectionBody>
                <AboutTitle grey>
                    MEET OUR CLIENTS
                </AboutTitle>
                <AboutWord grey style={{textAlign:'center'}}>
                    We have worked with notable brands and companies on different projects
                </AboutWord>
                <FlexDivCenter style={{ marginTop: '25px' }}>
                    <AboutImage src='images/companyNew.png' />
                </FlexDivCenter>
            </SectionBody>

            <SectionBody>
                <AboutTitle grey>
                    HAVE A PROJECT? WE CAN HELP 
                </AboutTitle>
                <FlexDivCenter style={{ marginTop: '25px' }}>
                    <MainButtonLink onClick={show}>LET'S TALK</MainButtonLink>
                </FlexDivCenter>
                <ContactModal visible={visible} setVisible={setVisible} />
            </SectionBody>
        </>

    )
}

export default About