import React from 'react'
import { EventHeaderWord, EventtWord, SectionBody } from '../styled-component/MainComponent'
import VideoComponent from '../Components/Projects/TopComponent/VideoComponent'

const Events = () => {
    return (
        <>

            <SectionBody>
                <div className="">

                    <div style={{ marginBottom: '100px' }}>
                        <VideoComponent video='/images/New.mp4' />
                    </div>
                    <EventHeaderWord>
                        First production designer & art director exhibition in the world
                    </EventHeaderWord>

                </div>
                <hr />
            </SectionBody>
     
            <SectionBody>
                <div className="">

                    <div style={{ marginBottom: '100px' }}>
                        <VideoComponent video='/images/projects/The-standup/video.mp4' />
                    </div>
                    <EventHeaderWord>
                        First Faith based exhibition in Africa
                    </EventHeaderWord>
                    <EventtWord>
                        10 artworks 1 story
                    </EventtWord>
                    <EventtWord>
                        Theme: When God writes your love story
                    </EventtWord>
                    <EventtWord>
                        A solo exhibition by BIG MOSSAD
                    </EventtWord>
                    <EventtWord>
                        The story of man’s journey back to God
                    </EventtWord>
                </div>
            </SectionBody>

        </>
    )
}

export default Events