import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { List, ListItem, RadioCheck, RadioCheckInside, RadioLabel, SortDescription, SortIcon } from './portfolio.style';
import { IconButton } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 330,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
  overflow: 'scroll',
  height: '500px'
};

export default function MobileModal({ categories, handleCategorySelection, selectedCategory, open, setOpen }) {
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <SortIcon src='images/icons/filter.png' />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <SortWrapper>
            <div style={{ display: 'flex' }}>
                <IconButton>
                  <SortIcon src='images/icons/filter.png'/>
                </IconButton>
                <SortPTag style={{ color: '#272727' }}>Filter</SortPTag>
            </div>
            <SearchWrapper>
                    <SearchInput placeholder='Search' id='search' name='search' />
                    <SearchIcon src='images/icons/search.png' />
                </SearchWrapper>
          </SortWrapper> */}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <SortDescription>Sort projects by Categories</SortDescription>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <List>
              {
                categories.map((cat, index) => (
                  <ListItem key={index}>
                    <IconButton onClick={(e) => handleCategorySelection(cat)}>
                      <RadioCheck checked={selectedCategory === cat}>
                        <RadioCheckInside checked={selectedCategory === cat} />
                      </RadioCheck>
                    </IconButton>
                    <RadioLabel>{cat}</RadioLabel>
                  </ListItem>
                ))
              }
            </List>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}