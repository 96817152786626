import styled from "styled-components";
import { MainPTag } from "../../styled-component/MainComponent";

export const ReviewWrapper = styled.section`
height: 694px;
background: #272727;
background-image: url("/images/review.png");
background-size: cover;
background-position: center center;
@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) and (max-width: 991px) {

}

@media (min-width: 576px) and (max-width: 767px) {

}

@media (max-width: 575px) {
  height: 873px;
}
`;

export const ReviewArrowButton = styled.button`
border: transparent;
background-color: transparent;
cursor: pointer;
`;

export const ReviewCard = styled.div`
background: url(768px-1k_Dissolve_Noise_Texture.png), linear-gradient(108.91deg, rgba(255, 255, 255, 0.4) 3.44%, rgba(255, 255, 255, 0.1) 100%);
box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.2);
backdrop-filter: blur(25px);
border-radius: 40px;
height: 300px;
text-align: justify;
padding: 30px 20px;
width: 40%;
margin-top: 30px;
@media (max-width: 575px) {
  width: 90%;
  height: 175px;
}
`;
 
export const CenterReviewNave = styled.div`
display: flex;
justify-content: center;
margin-top: 30px;
gap: 10px;
@media (max-width: 575px) {
  
  }
`;

export const ReviewNav = styled.div`
width: 40px;
height: 0px;
border:  ${({ activeRev }) => (activeRev === "true" ? "1px solid #B41B00" : "1px solid #FFFFFF")};
`;

export const ReviewCompany = styled(MainPTag)`
font-style: italic;
font-size: 16px;
`;

export const ReviewName = styled(MainPTag)`
font-weight: 700;
font-size: 16px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
margin-top: 60px;
@media (min-width: 992px) and (max-width: 1199px) {
  margin-top: 50px;
}

@media (min-width: 768px) and (max-width: 991px) {
  margin-top: 45px;
}

@media (min-width: 576px) and (max-width: 767px) {
  margin-top: 35px;
}
@media (max-width: 575px) {
  margin-top: 30px;
}
`;

