import styled from "styled-components";

export const ContentWrapper = styled.section`
    padding: 3rem 5rem 3rem 5rem ;
    @media screen and (max-width: 575px) {
        padding: 0.5rem 2rem;
    }
`;
export const ContentCardFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (min-width: 992px) and (max-width: 1199px) {
  
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;
export const ContentCard = styled.div`
  height:555px;
  width: 50%;
  @media (min-width: 992px) and (max-width: 1199px) {
  
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`;
export const ContentCardImage = styled.img`
  height: 80%;
  width: 100%;
  object-fit: cover;
`;

export const ContentCardWord = styled.p`
    color: #000;
    font-family: Circular Std;
    font-size: 28px;
    font-style: normal;
    font-weight: 450;
    line-height: 36px;
    margin: 0;
`;
