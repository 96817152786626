export const projects = [
  {
    "id": 68,
    "slug": 'GOL',
    "feature": 1,
    "mainImage": '/images/projects/GOL/gol.jpeg',
    "blackImage": '/images/projects/GOL/gol.jpeg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/GOL/BTS1.JPG', '/images/projects/GOL/BTS2.JPG'],
    "name": "Gangs Of Lagos",
    "category": "Feature Film",
    "production_year": 2021,
    "client": "Mr Gbenga ( Greoh Studio)",
    "role": "Set Dresser & Property Master",
    "video_link": "/images/projects/GOL/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 38,
    "slug": 'Zabin',
    "feature": 1,
    "mainImage": "/images/projects/Zabin/color.jpg",
    "blackImage": "/images/projects/Zabin/color.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Zabin Halima (Netflix And Unesco)",
    "category": "Short Film",
    "production_year": 2022,
    "client": "Netflix And Unesco",
    "role": "Art Director",
    "video_link": "/images/projects/Zabin/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 11,
    "slug": 'Flawsome',
    "feature": 1,
    "mainImage": '/images/projects/flawsome/COLOURED.jpg',
    "blackImage": '/images/projects/flawsome/BLACK.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/flawsome/BTS1.jpg', '/images/projects/flawsome/BTS2.jpg'],
    "name": "Flawsome",
    "category": "Web Series",
    "production_year": 2022,
    "client": "Showmax Original",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/flawsome/TRAILER_VIDEO.mp4",
    "video_l": "/images/projects/flawsome/video.mp4",
    "slider": ['/images/projects/flawsome/SLIDE1.jpg', '/images/projects/flawsome/SLIDE2.jpg', '/images/projects/flawsome/SLIDE3.jpg', '/images/projects/flawsome/SLIDE4.jpg']
  },
  {
    "id": 5,
    "slug": 'Sony',
    "feature": 1,
    "mainImage": "/images/projects/Song/main.jpg",
    "blackImage": "/images/projects/Song/main.jpg",
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Song/behindthescene.jpg'],
    "name": "Sony Cine Tour",
    "category": "Set Design",
    "production_year": 2023,
    "client": "Sony",
    "role": "Production Designer And Art Director",
    "video_link": "/images/projects/Song/SONY_cine_tour.mp4",
    "video_l": "",
    "slider": ["/images/projects/Song/slider1.jpg", "/images/projects/Song/slider2.jpg", "/images/projects/Song/slider3.jpg"]
  },
  {
    "id": 81,
    "slug": 'COKESTUDIO',
    "feature": 1,
    "mainImage": "/images/projects/Lock-up/coke.png",
    "blackImage": "/images/projects/Lock-up/coke.png",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "COKE STUDIO",
    "category": "SET DESIGN",
    "production_year": 2023,
    "client": "",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 34,
    "slug": 'Mtn',
    "feature": 1,
    "mainImage": '/images/projects/Mtn/color.PNG',
    "blackImage": '/images/projects/Mtn/black.jpg',
    "setDesignSketche": [], 
    "behindTheScene": ['/images/projects/Mtn/bts.jpg'],
    "name": "Mtn X Youtube",
    "category": "Commercial",
    "production_year": 2022,
    "client": "Mtn X Youtube",
    "role": "Art Director",
    "video_link": "/images/projects/Mtn/video.mp4",
    "video_l": "",
    "slider": ['/images/projects/Mtn/slide.jpg', '/images/projects/Mtn/slide2.jpg', '/images/projects/Mtn/slide3.jpg', '/images/projects/Mtn/slide4.jpg', '/images/projects/Mtn/slide5.jpg']
  },
  {
    "id": 6,
    "slug": 'Hackathon',
    "feature": 0,
    "mainImage": '/images/projects/Hackathon/main.png',
    "blackImage": '/images/projects/Hackathon/black.png',
    "setDesignSketche": ['/images/projects/Hackathon/sketche1.jpg', '/images/projects/Hackathon/sketche2.jpg'],
    "behindTheScene": ['/images/projects/Hackathon/Scene.png', '/images/projects/Hackathon/Scene2.png'],
    "name": "Hackathon",
    "category": "Event Design",
    "production_year": 2023,
    "client": "Ilorin Innovation Hub And The Governor Of Ilorin",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Hackathon/BTS_ILORIN_HACKATHON.mp4",
    "video_l": "",
    "slider": ["/images/projects/Hackathon/slider1.png", "/images/projects/Hackathon/slider2.png", "/images/projects/Hackathon/slider3.png", "/images/projects/Hackathon/slider4.png"]
  },
  {
    "id": 77,
    "slug": 'CALYPSO',
    "feature": 0,
    "mainImage": "/images/projects/Lock-up/calypso.jpg",
    "blackImage": "/images/projects/Lock-up/calypso.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "CALYPSO RELAUNCH EVENT DESIGN",
    "category": "Set Design",
    "production_year": 2023,
    "client": "",
    "role": "",
    "video_link": "/images/projects/calypso/video.mp4",
    "video_l": "/images/projects/calypso/video.mp4",
    "slider": []
  },
  {
    "id": 29,
    "slug": 'BBN',
    "feature": 0,
    "mainImage": '/images/projects/bbn-interview/bbn.jpg',
    "blackImage": '/images/projects/bbn-interview/bbn.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/bbn-interview/bts1.HEIC', '/images/projects/bbn-interview/bts2.HEIC'],
    "name": "Big Brother Naija; Meet The Housemate",
    "category": "Tv Show",
    "production_year": 2022,
    "client": "Alo 360 Media",
    "role": "Art Director",
    "video_link": "/images/projects/bbn-interview/video.mp4",
    "video_l": "/images/projects/bbn-interview/video.mp4",
    "slider": ['/images/projects/bbn-interview/slides1.PNG','/images/projects/bbn-interview/slides2.PNG','/images/projects/bbn-interview/slides3.PNG','/images/projects/bbn-interview/slides4.PNG','/images/projects/bbn-interview/slides5.PNG']
  },
  {
    "id": 31,
    "slug": 'GOLHF',
    "feature": 0,
    "mainImage": '/images/projects/Store/jol.jpg',
    "blackImage": '/images/projects/Store/jol.jpg',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "J.O.L By High Fashion",
    "category": "Fashion Shoot",
    "production_year": 2022,
    "client": "Octalayke For Rahman Jago",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 1,
    "slug": 'Trillion',
    "feature": 0,
    "mainImage": "/images/projects/Trillion/main.jpg",
    "blackImage": "/images/projects/Trillion/main.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Trillion Dollar Dream",
    "category": "Music Video",
    "production_year": 2023,
    "client": "Ibeji Xtrim Studio",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Trillion/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 2,
    "slug": 'Chocolate',
    "feature": 0,
    "mainImage": "/images/projects/Store/chocolate.jpg",
    "blackImage": "/images/projects/Store/chocolate.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Chocolate Hour",
    "category": "Music Video",
    "production_year": 2023,
    "client": "Ibeji Xtrim Studio",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 3,
    "slug": 'Pink',
    "feature": 0,
    "mainImage": "/images/projects/Store/pink.jpg",
    "blackImage": "/images/projects/Store/pink.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Pink Pineapple",
    "category": "Music Video",
    "production_year": 2023,
    "client": "Ibeji Xtrim Studio",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 4,
    "slug": 'LockUp',
    "feature": 0,
    "mainImage": "/images/projects/Lock-up/lock.jpg",
    "blackImage": "/images/projects/Lock-up/lock.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Lock Up By Nonso Amandi Ft Zinoleesky",
    "category": "Music Video",
    "production_year": 2023,
    "client": "Uaxstudio",
    "role": "Art Director",
    "video_link": "/images/projects/Lock-up/musicvideo .mp4",
    "video_l": "",
    "slider": []
  },

  {
    "id": 7,
    "slug": 'Betensured',
    "feature": 0,
    "mainImage": '/images/projects/bet-ensured/Coloured.jpg',
    "blackImage": '/images/projects/bet-ensured/Black.png',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Bet Ensured Commerical",
    "category": "Commercial",
    "production_year": 2023,
    "client": "Krex Studios",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/bet-ensured/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 8,
    "slug": 'Viva',
    "feature": 0,
    "mainImage": "/images/projects/Store/viva.jpg",
    "blackImage": "/images/projects/Store/viva.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Viva Branding For Nancy Isime",
    "category": "Tv Show",
    "production_year": 2023,
    "client": "Expose Brand",
    "role": "Branding Agent",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 9,
    "slug": 'Merry',
    "feature": 0,
    "mainImage": "/images/projects/merry-men/Merrymen.jpg",
    "blackImage": "/images/projects/merry-men/Merrymen.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Merry Men 3",
    "category": "Feature Film",
    "production_year": 2023,
    "client": "Cooperate World Entertainment & Filmone",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/merry-men/video.mp4",
    "video_l": "",
    "slider": ["/images/projects/merry-men/slider.JPG", "/images/projects/merry-men/slider2.JPG", "/images/projects/merry-men/slider3.JPG", "/images/projects/merry-men/slider4.JPG", "/images/projects/merry-men/slider5.JPG"]
  },
  {
    "id": 10,
    "slug": 'Sugar',
    "feature": 0,
    "mainImage": "/images/projects/Store/sugar.jpg",
    "blackImage": "/images/projects/Store/sugar.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Sugar Rush 2",
    "category": "Feature Film",
    "production_year": 2023,
    "client": "Greoh Studio",
    "role": "2nd Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []

  },

  {
    "id": 12,
    "slug": 'Jossyann',
    "feature": 0,
    "mainImage": '/images/projects/Jossy/Colour.jpg',
    "blackImage": '/images/projects/Jossy/Black.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Jossy/BTS1.jpg' ,'/images/projects/Jossy/BTS2.jpg', '/images/projects/Jossy/BTS3.jpg'],
    "name": "Jossyann Music Video",
    "category": "Music Video",
    "production_year": 2022,
    "client": "Sisi Eko Jossyann",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Jossy/video.MP4",
    "video_l": "",
    "slider": ['/images/projects/Jossy/Slide1.jpg']
  },
  {
    "id": 13,
    "slug": 'Podcast',
    "feature": 0,
    "mainImage": "/images/projects/Store/podcast.jpg",
    "blackImage": "/images/projects/Store/podcast.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Podcast Studio Design",
    "category": "Set Design",
    "production_year": 2022,
    "client": "Josh Olawole",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 14,
    "slug": 'Theivvyhouse',
    "feature": 0,
    "mainImage": '/images/projects/Ivvy-house/Coloured.jpg',
    "blackImage": '/images/projects/Ivvy-house/Black.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Ivvy-house/BTS02.jpg','/images/projects/Ivvy-house/so.jpg'],
    "name": "Theivvyhouse Fashion Shoot",
    "category": "Fashion Shoot",
    "production_year": 2022,
    "client": "The Ivvy House",
    "role": "Production Designer, Creative Director & Art Director",
    "video_link": "/images/projects/Ivvy-house/video.mp4",
    "video_l": "",
    "slider": ['/images/projects/Ivvy-house/Slide1.jpg','/images/projects/Ivvy-house/Slide2.jpg','/images/projects/Ivvy-house/Slide3.jpg','/images/projects/Ivvy-house/Slide4.jpg','/images/projects/Ivvy-house/Slide5.jpg']
  },
  {
    "id": 15,
    "slug": 'Lareto',
    "feature": 0,
    "mainImage": '/images/projects/lareto/main.JPG',
    "blackImage": '/images/projects/lareto/main.JPG',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/lareto/Bts1.jpg', '/images/projects/lareto/BTS2.jpg', '/images/projects/lareto/Bts3.jpg'],
    "name": "Lareto Fashion Stagerunway",
    "category": "Fashion Shoot",
    "production_year": 2022,
    "client": "Urban Vision 360",
    "role": "Production Designer & Art Director",
    "video_link": '/images/projects/lareto/video.mp4',
    "video_l": '',
    "slider": ['/images/projects/lareto/Slide 1.jpg', '/images/projects/lareto/Slide 2.JPG']
  },
  {
    "id": 16,
    "slug": 'Chizzy',
    "feature": 0,
    "mainImage": '/images/projects/Chizzy/Coloured.PNG',
    "blackImage": '/images/projects/Chizzy/Black.PNG',
    "setDesignSketche": ['/images/projects/Chizzy/Setsketch1.png','/images/projects/Chizzy/Setsketch2.png'],
    "behindTheScene": ['/images/projects/Chizzy/BTS1.jpg','/images/projects/Chizzy/BTS1.jpg'],
    "name": "Chizzy Online Music Video",
    "category": "Music Video",
    "production_year": 2022,
    "client": "Ifeme Cs",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": ['/images/projects/Chizzy/Slide1.jpg', '/images/projects/Chizzy/Slide2.jpg', '/images/projects/Chizzy/Slide3.jpg', '/images/projects/Chizzy/Slide4.jpg']
  },
  {
    "id": 17,
    "slug": 'AllorNothing',
    "feature": 0,
    "mainImage": '/images/projects/AllorNothing/Card_colored.jpg',
    "blackImage": '/images/projects/AllorNothing/black.jpg',
    "setDesignSketche": ['/images/projects/AllorNothing/Set_sketch.jpg'],
    "behindTheScene": ['/images/projects/AllorNothing/bts2.jpg','/images/projects/AllorNothing/bts3.jpg','/images/projects/AllorNothing/bts5.jpg'],
    "name": "All Or Nothing",
    "category": "Feature Film",
    "production_year": 2022,
    "client": "King Views Production & Picado",
    "role": "Art Director",
    "video_link": '/images/projects/AllorNothing/video.mp4',
    "video_l": '',
    "slider": ['/images/projects/AllorNothing/slide1.jpg', '/images/projects/AllorNothing/slide2.jpg','/images/projects/AllorNothing/slide3.jpg']
  },
  {
    "id": 18,
    "slug": 'Grey',
    "feature": 0,
    "mainImage": "/images/projects/Store/Grey.jpg",
    "blackImage": "/images/projects/Store/Grey.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Grey Velvet Christmas Set Design",
    "category": "Set Design",
    "production_year": 2022,
    "client": "Grey Velvet Store",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Store/grey.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 19,
    "slug": 'Amvca',
    "feature": 0,
    "mainImage": '/images/projects/Amvca/main.jpg',
    "blackImage": '/images/projects/Amvca/main.jpg',
    "setDesignSketche": ['/images/projects/Amvca/stekch.png', '/images/projects/Amvca/sketch2.jpg'],
    "behindTheScene": ['/images/projects/Amvca/bts.jpg', '/images/projects/Amvca/bts3.jpg'],
    "name": "Amvca Photoboot( African Magic Viewers Choice Awards)",
    "category": "Set Design",
    "production_year": 2022,
    "client": "Kola Osalusi",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Amvca/BTSPhotoboothforamvca.mp4",
    "video_l": "",
    "slider": ['/images/projects/Amvca/slide2.jpg', '/images/projects/Amvca/slide3.jpg',  '/images/projects/Amvca/slide4.jpg', '/images/projects/Amvca/slider6.jpg', '/images/projects/Amvca/slider7.jpg']
  },
  {
    "id": 20,
    "slug": 'LafiyaEnglish',
    "feature": 0,
    "mainImage":  '/images/projects/lafiya-tele/Lafiya.jpg',
    "blackImage":  '/images/projects/lafiya-tele/Lafiya.jpg',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Lafiya Telehealth Commercial( English)",
    "category": "Commercial",
    "production_year": 2022,
    "client": "Lucy Ameh",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/lafiya-tele/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 21,
    "slug": 'LafiyaPidgin',
    "feature": 0,
    "mainImage":  '/images/projects/lafiya-tele/Lafiya.jpg',
    "blackImage":  '/images/projects/lafiya-tele/Lafiya.jpg',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Lafiya Telehealth Commercial( Pidgin)",
    "category": "Commercial",
    "production_year": 2022,
    "client": "Lucy Ameh",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/lafiya-tele/pidgin/video.mp4",
    "video_l": "4",
    "slider": []
  },
  {
    "id": 22,
    "slug": 'LafiyaHausa',
    "feature": 0,
    "mainImage":  '/images/projects/lafiya-tele/Lafiya.jpg',
    "blackImage":  '/images/projects/lafiya-tele/Lafiya.jpg',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Lafiya Telehealth Commercial (Hausa)",
    "category": "Commercial",
    "production_year": 2022,
    "client": "Lucy Ameh",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/lafiya-tele-hausa/video2.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 23,
    "slug": 'Ini',
    "feature": 0,
    "mainImage": '/images/projects/Ini/Coloured.jpg',
    "blackImage": '/images/projects/Ini/black.jpg',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Ini The Musical",
    "category": "Stage Play",
    "production_year": 2022,
    "client": "Sisiano",
    "role": "Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 24,
    "slug": 'LoyaEnglish',
    "feature": 0,
    "mainImage": "/images/projects/Loyal-milk/loya.jpg",
    "blackImage": "/images/projects/Loyal-milk/loya.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Loya Milk Commercial (English )",
    "category": "Commercial",
    "production_year": 2022,
    "client": "Promacidor",
    "role": "Set Dresser & Property Manager",
    "video_link": "/images/projects/Loyal-milk/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 25,
    "slug": 'LoyaHausa',
    "feature": 0,
    "mainImage": "/images/projects/Lock-up/Color.jpeg",
    "blackImage": "/images/projects/Lock-up/Black.jpeg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Loya Milk Commercial (Hausa)",
    "category": "Commercial",
    "production_year": 2022,
    "client": "Promacidor",
    "role": "Set Dresser & Property Manager",
    "video_link": "/images/projects/Loyal-milk/video.mp4",
    "video_l": "/images/projects/Loyal-milk/video.mp4",
    "slider": []
  },
  {
    "id": 26,
    "slug": 'Teens',
    "feature": 0,
    "mainImage": "/images/projects/Teens-Bootcamp/bible.jpg",
    "blackImage": "/images/projects/Teens-Bootcamp/bible.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Teens Bible Boot Camp",
    "category": "Stage Design",
    "production_year": 2022,
    "client": "Nlwc( New And Living Way Church)",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Teens-Bootcamp/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 27,
    "slug": 'StageNlwc',
    "feature": 0,
    "mainImage": "/images/projects/Nlwc/black.jpg",
    "blackImage": "/images/projects/Nlwc/black.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Stage Design For Nlwc",
    "category": "Set Design",
    "production_year": 2022,
    "client": "Nlwc( New And Living Way Church)",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Nlwc/video.MOV",
    "video_l": "",
    "slider": ["/images/projects/Nlwc/slide1.jpg","/images/projects/Nlwc/slide2.jpg","/images/projects/Nlwc/slide3.jpg",]
  },
  {
    "id": 28,
    "slug": 'MOT',
    "feature": 0,
    "mainImage": '/images/projects/MoT/Card.jpg',
    "blackImage": '/images/projects/MoT/Card.jpg', 
    "setDesignSketche": ['/images/projects/MoT/sketch.jpg'],
    "behindTheScene": ['/images/projects/MoT/BTS1.jpg', '/images/projects/MoT/Bts2.jpg'],
    "name": "Moment Of Truth",
    "category": "Tv Show",
    "production_year": 2022,
    "client": "Mr Dare Johnson",
    "role": "Art Director",
    "video_link": "/images/projects/MoT/video.mp4",
    "video_l": "",
    "slider": ['/images/projects/MoT/slide1.jpg','/images/projects/MoT/slide2.jpg', '/images/projects/MoT/slide3.jpg', '/images/projects/MoT/slide4.jpg']
  },

  {
    "id": 30,
    "slug": 'TheStandUp',
    "feature": 0,
    "mainImage": '/images/projects/The-standup/color.jpg',
    "blackImage": '/images/projects/The-standup/color.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/The-standup/bts.jpg','/images/projects/The-standup/bts2.jpg'],
    "name": "The Standup",
    "category": "Feature Film",
    "production_year": 2022,
    "client": "King Views Production",
    "role": "Property Master",
    "video_link": "/images/projects/The-standup/video.mp4",
    "video_l": "",
    "slider": ['/images/projects/The-standup/slide.jpg','/images/projects/The-standup/slide1.jpg','/images/projects/The-standup/slide2.jpg','/images/projects/The-standup/slide3.jpg',]
  },
  { 
    "id": 32,
    "slug": 'Meadow',
    "feature": 0,
    "mainImage": '/images/projects/Meadow/black.JPG',
    "blackImage": '/images/projects/Meadow/black2.JPG',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Meadow/bts.jpg'],
    "name": "Meadow School Stage Drama",
    "category": "Set Design",
    "production_year": 2022,
    "client": "Meadow School",
    "role": "Art Director",
    "video_link": "/images/projects/Meadow/IMG_9578.MOV",
    "video_l": "/images/projects/Meadow/IMG_9571.MOV",
    "slider": ['/images/projects/Meadow/slide.jpg', '/images/projects/Meadow/slide2.jpg']
  },
  {
    "id": 33,
    "slug": 'Egfm',
    "feature": 0,
    "mainImage": "/images/projects/Store/egfm.jpg",
    "blackImage": "/images/projects/Store/egfm.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Egfm Becon Interview Shoot",
    "category": "Commercial",
    "production_year": 2022,
    "client": "Egfm(Eternal Glorious Fountain Ministry)",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },

  {
    "id": 35,
    "slug": 'Mskd',
    "feature":0,
    "mainImage": "/images/projects/Store/Mskd.jpg",
    "blackImage": "/images/projects/Store/Mskd.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Mskd And Sptr",
    "category": "ART EXHIBITION",
    "production_year": 2022,
    "client": "Horlaoflagos",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Mskd/video.MOV",
    "video_l": "",
    "slider": []
  },
  {
    "id": 36,
    "slug": 'Unlocked',
    "feature": 0,
    "mainImage": "/images/projects/bbn-unlocked/unlocked.JPG",
    "blackImage": "/images/projects/bbn-unlocked/unlocked.JPG",
    "setDesignSketche": ["/images/projects/bbn-unlocked/sketch.jpg", "/images/projects/bbn-unlocked/sketch1.jpg"],
    "behindTheScene": ["/images/projects/bbn-unlocked/BTS1.jpg", "/images/projects/bbn-unlocked/BTS2.jpg"],
    "name": "Unlocked For Bbn",
    "category": "Tv Show",
    "production_year": 2022,
    "client": "Alo 360 Media",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/bbn-unlocked/video.MP4",
    "video_l": "",
    "slider": ["/images/projects/bbn-unlocked/Slide1.jpg","/images/projects/bbn-unlocked/Slide2.jpg","/images/projects/bbn-unlocked/Slide3.jpg"]
  },
  {
    "id": 37,
    "slug": 'Rebook',
    "feature": 0,
    "mainImage": "/images/projects/Reboot/reboot.png",
    "blackImage": "/images/projects/Reboot/reboot.png",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Rebook Camp Set Design",
    "category": "Tv Show",
    "production_year": 2022,
    "client": "Celebration Church International",
    "role": "2nd Art Director & Set Dresser",
    "video_link":  "/images/projects/Reboot/video.mp4",
    "video_l":  "/images/projects/Reboot/video.mp4",
    "slider": ["/images/projects/Reboot/slide2.jpg", "/images/projects/Reboot/slide.jpg"]
  },

  {
    "id": 39,
    "slug": 'LoveDoctor',
    "feature": 0,
    "mainImage": "/images/projects/Store/love.jpg",
    "blackImage": "/images/projects/Store/love.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Love Doctor",
    "category": "Tv Show",
    "production_year": 2022,
    "client": "Ckay Show",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 40,
    "slug": 'Class',
    "feature": 0,
    "mainImage": "/images/projects/Lock-up/Color.jpeg",
    "blackImage": "/images/projects/Lock-up/Black.jpeg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "A Class With Hannah Bleacher",
    "category": "Training",
    "production_year": 2022,
    "client": "Production Designer Of Blank Panter",
    "role": "A Student",
    "video_link": "",
    "video_l": "",
    "slider": []
  },

  {
    "id": 42,
    "slug": 'Billion',
    "feature": 0,
    "mainImage": "/images/projects/CCI/cci.jpg",
    "blackImage": "/images/projects/CCI/cciblack.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "A Billion Soul's Documentary",
    "category": "Documentary",
    "production_year": 2022,
    "client": "Celebration Church International",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/CCI/Documentarry.mp4",
    "video_l": "/images/projects/CCI/BTS.mp4",
    "slider": []
  },
  {
    "id": 43,
    "slug": 'Elevation',
    "feature": 0,
    "mainImage": "/images/projects/elevation/Color.jpg",
    "blackImage": '/images/projects/elevation/Black.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/elevation/Bts1.jpg'],
    "name": "Elevation Studio Set Design",
    "category": "Set Design",
    "production_year": 2022,
    "client": "Elevation Church",
    "role": "Art Director",
    "video_link": "/images/projects/elevation/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 44,
    "slug": 'Blue',
    "feature": 0,
    "mainImage": "/images/projects/blue-nun/main.JPG",
    "blackImage": "/images/projects/blue-nun/black.JPG",
    "setDesignSketche": [],
    "behindTheScene": ["/images/projects/blue-nun/slide1.jpg", "/images/projects/blue-nun/slider2.jpg"],
    "name": "Blue Nun Commercial",
    "category": "Commercial",
    "production_year": 2022,
    "client": "Mr Oladele Onasanya",
    "role": "Asst. Art Director, Property Manager & Ser Dresser",
    "video_link": "/images/projects/blue-nun/video.MOV",
    "video_l": "/images/projects/blue-nun/video1.MOV",
    "slider": []
  },
  {
    "id": 45,
    "slug": 'Zeus',
    "feature": 0,
    "mainImage": "/images/projects/Zeus/color.jpg",
    "blackImage":  "/images/projects/Zeus/black.png",
    "setDesignSketche": [ "/images/projects/Zeus/sketch.jpg",  "/images/projects/Zeus/sketch2.jpg"],
    "behindTheScene": [],
    "name": "Zeus Show Your Working Music Video Shoot",
    "category": "Music Video",
    "production_year": 2022,
    "client": "Zeus",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Zeus/video.MOV",
    "video_l": "/images/projects/Zeus/video2.MOV",
    "slider": []
  },
  {
    "id": 46,
    "slug": 'Kristipedia',
    "feature": 0,
    "mainImage":  "/images/projects/kristipedia/poster.jpg",
    "blackImage": '/images/projects/kristipedia/poster2.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/kristipedia/bts.jpg', '/images/projects/kristipedia/bts2.jpg'],
    "name": "Kristipedia",
    "category": "Feature Film",
    "production_year": 2022,
    "client": "Marauders",
    "role": "Production Designer",
    "video_link": "",
    "video_l": "",
    "slider": ['/images/projects/kristipedia/slide.jpg', '/images/projects/kristipedia/slide2.jpg', '/images/projects/kristipedia/slide3.jpg', '/images/projects/kristipedia/slide4.jpg', '/images/projects/kristipedia/slide5.jpg']
  },
  {
    "id": 47,
    "slug": 'TMM',
    "feature": 0,
    "mainImage": '/images/projects/TMM/main.PNG',
    "blackImage": '/images/projects/TMM/main2.PNG',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/TMM/bts.jpg', '/images/projects/TMM/bts2.jpg', '/images/projects/TMM/bts3.jpg'],
    "name": "The Manipulated Man",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Ebony Life Creative Academy",
    "role": "Set Dresser & Property Master",
    "video_link": "",
    "video_l": "",
    "slider": ['/images/projects/TMM/slide.PNG', '/images/projects/TMM/slide2.PNG']
  },
  {
    "id": 48,
    "slug": 'Self',
    "feature": 0,
    "mainImage": '/images/projects/Self/main.jpg',
    "blackImage": '/images/projects/Self/main.jpg',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Self",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Ebony Life Creative Academy",
    "role": "Set Dresser & Property Master",
    "video_link": "/images/projects/Self/video.mp4",
    "video_l": "",
    "slider": ['/images/projects/Self/slider.jpg','/images/projects/Self/slider2.jpg','/images/projects/Self/slider3.jpg','/images/projects/Self/slider4.jpg','/images/projects/Self/slider5.jpg']
  },
  {
    "id": 49,
    "slug": 'Dilemma',
    "feature": 0,
    "mainImage": '/images/projects/dilemma/Coloured.jpg',
    "blackImage": '/images/projects/dilemma/Black.jpg',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Dilemma",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Ebony Life Creative Academy",
    "role": "Property Master",
    "video_link": '/images/projects/dilemma/Video.mp4',
    "video_l": '/images/projects/dilemma/Video.mp4',
    "slider": []
  },
  {
    "id": 50,
    "slug": 'Ifeloju',
    "feature": 0,
    "mainImage": '/images/projects/Ifeloju/Coloured.jpg',
    "blackImage": '/images/projects/Ifeloju/Black.jpg',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Ifeloju",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Ebony Life Creative Academy",
    "role": "Set Dresser & Property Master",
    "video_link": '/images/projects/Ifeloju/Video.mp4',
    "video_l": '',
    "slider": []
  },
  {
    "id": 51,
    "slug": 'Fury',
    "feature": 0,
    "mainImage": '/images/projects/Fury/color.jpg',
    "blackImage": '/images/projects/Fury/black.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Fury/BtS1.jpg','/images/projects/Fury/Bts2.jpg'],
    "name": "Fury",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Ebony Life Creative Academy",
    "role": "Set Dresser & Property Master",
    "video_link": "",
    "video_l": "",
    "slider": ['/images/projects/Fury/slide1.jpg','/images/projects/Fury/slide2.jpg','/images/projects/Fury/slide3.jpg','/images/projects/Fury/slide4.jpg','/images/projects/Fury/slide5.jpg',]
  },
  {
    "id": 52,
    "slug": 'Monitoring',
    "feature": 0,
    "mainImage": '/images/projects/Monitoring-spirit/Poster.jpg',
    "blackImage": '/images/projects/Monitoring-spirit/Black.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Monitoring-spirit/BTS1.jpg', '/images/projects/Monitoring-spirit/BTS2.jpg'],
    "name": "Monitoring Spirit",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Afriff Selection",
    "role": "Set Dresser",
    "video_link": "/images/projects/Monitoring-spirit/video.mp4",
    "video_l": "",
    "slider": []
  },
  {
    "id": 53,
    "slug": 'TNBT',
    "feature": 0,
    "mainImage": "/images/projects/TNBT/main.jpg",
    "blackImage": "/images/projects/TNBT/main.jpg",
    "setDesignSketche": [],
    "behindTheScene": ["/images/projects/TNBT/bts.jpg", "/images/projects/TNBT/bts2.jpg"],
    "name": "The Next Best Thing",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Ebony Life Creative Academy",
    "role": "Production Designer And Art Director",
    "video_link": "/images/projects/TNBT/video.mov",
    "video_l": "",
    "slider": ["/images/projects/TNBT/slider.jpg", "/images/projects/TNBT/slider2.jpg", "/images/projects/TNBT/slider3.jpg", "/images/projects/TNBT/slider4.jpg"]
  },
  {
    "id": 54,
    "slug": 'GN',
    "feature": 0,
    "mainImage": '/images/projects/Generation-next/POSTER.JPG',
    "blackImage": '/images/projects/Generation-next/POSTER.JPG',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Generation-next/bts.jpg','/images/projects/Generation-next/bts2.jpg','/images/projects/Generation-next/bts3.jpg'],
    "name": "Generation Next",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Mun Media",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Generation-next/video.mp4",
    "video_l": "",
    "slider": ['/images/projects/Generation-next/slide.jpg', '/images/projects/Generation-next/slide2.JPG', '/images/projects/Generation-next/slide3.JPG', '/images/projects/Generation-next/slide4.JPG']
  },
  {
    "id": 55,
    "slug": 'TrashM',
    "feature": 0,
    "mainImage": '/images/projects/Trash/Coloured.jpg',
    "blackImage": '/images/projects/Trash/Black.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Trash/Bts1.jpg', '/images/projects/Trash/Bts2.jpg'],
    "name": "Trash Monster",
    "category": "Motion Film",
    "production_year": 2021,
    "client": "Art Cast Tribe",
    "role": "Art Director",
    "video_link": "",
    "video_l": "",
    "slider": ['/images/projects/Trash/slide1.JPG', '/images/projects/Trash/slide2.jpg ', '/images/projects/Trash/slide3.JPG', '/images/projects/Trash/slide4.JPG' ]
  },
  {
    "id": 56,
    "slug": 'HICT',
    "feature": 0,
    "mainImage": "/images/projects/Heirs/heirs.jpg",
    "blackImage": "/images/projects/Heirs/heirs.jpg",
    "setDesignSketche": [],
    "behindTheScene": ["/images/projects/Heirs/slid.jpg"],
    "name": "Heir Insurance Commercial ( Tenancy)",
    "category": "Commercial",
    "production_year": 2021,
    "client": "Heir Insurance( Tony Onumelu)",
    "role": "Production Designer & Art Director",
    "video_link": '/images/projects/Heirs/video.mp4',
    "video_l": '',
    "slider": []
  },
  {
    "id": 57,
    "slug": 'HICM',
    "feature": 0,
    "mainImage": "/images/projects/Lock-up/Color.jpeg",
    "blackImage": "/images/projects/Lock-up/Black.jpeg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Heir Insurance Commercial ( Motor Insurance)",
    "category": "Commercial",
    "production_year": 2021,
    "client": "Heir Insurance( Tony Onumelu)",
    "role": "Production Designer & Art Director",
    "video_link": '/images/projects/Heirs/video.mp4',
    "video_l": '/images/projects/Heirs/video.mp4',
    "slider": []
  },
  {
    "id": 58,
    "slug": 'Nostalgia',
    "feature": 0,
    "mainImage": '/images/projects/Nostalgia/main.jpg',
    "blackImage": '/images/projects/Nostalgia/main2.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Nostalgia/BTS1.jpg', '/images/projects/Nostalgia/bts2.jpg'],
    "name": "Nostalgia",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Naomi Rhodas & Titofunmi",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/Nostalgia/video.mov",
    "video_l": "",
    "slider": []
  },
  {
    "id": 59,
    "slug": 'TWO',
    "feature": 0,
    "mainImage": "/images/projects/Lock-up/Color.jpeg",
    "blackImage": "/images/projects/Lock-up/Black.jpeg",
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/Two/bts.jpg', '/images/projects/Two/bts2.jpg'],
    "name": "The Way Out",
    "category": "Silent Film",
    "production_year": 2021,
    "client": "Ebony Life Creative Academy",
    "role": "Art Director",
    "video_link": "",
    "video_l": "",
    "slider": ['/images/projects/Two/slide.jpg', '/images/projects/Two/slide2.jpg','/images/projects/Two/slide3.jpg']
  },
  {
    "id": 60,
    "slug": 'WMV',
    "feature": 0,
    "mainImage": "/images/projects/wait/main.jpeg",
    "blackImage": "/images/projects/wait/main.jpeg",
    "setDesignSketche": [],
    "behindTheScene": ["/images/projects/wait/bts.JPG", "/images/projects/wait/bts2.JPG"],
    "name": "Wait Music Video",
    "category": "Music Video",
    "production_year": 2021,
    "client": "Sammy Lee- Music Director & Ifeme Cs - Producer",
    "role": "Production Designer & Art Director",
    "video_link": "/images/projects/wait/video.mp4",
    "video_l": "",
    "slider": ["/images/projects/wait/slider.jpeg","/images/projects/wait/slider2.jpeg", "/images/projects/wait/slider3.jpeg", "/images/projects/wait/slider4.jpeg", "/images/projects/wait/slider5.jpeg", "/images/projects/wait/slider6.JPG", "/images/projects/wait/slider7.jpeg"]
  },
  {
    "id": 61,
    "slug": 'FM',
    "feature": 0,
    "mainImage": '/images/projects/family-meeting/Poster.jpg',
    "blackImage": '/images/projects/family-meeting/Black.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/family-meeting/BTS1.jpg', '/images/projects/family-meeting/BTS2.jpg'],
    "name": "Family Meeting",
    "category": "Short Film",
    "production_year": 2021,
    "client": "Ulo Aku",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 62,
    "slug": 'TTGL',
    "feature": 0,
    "mainImage": "/images/projects/Store/love2.jpeg",
    "blackImage": "/images/projects/Store/love1.jpeg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "This Thing Called Love",
    "category": "Web Series",
    "production_year": 2021,
    "client": "Gems Studio",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 63,
    "slug": 'TMC',
    "feature": 0,
    "mainImage":  '/images/projects/men-club-4/main.JPG',
    "blackImage":  '/images/projects/men-club-4/main.JPG',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "The Men's Club Season 4",
    "category": "Web Series",
    "production_year": 2021,
    "client": "Urban Vision",
    "role": "Production Designer & Art Director",
    "video_link":  '/images/projects/men-club-4/video.mp4',
    "video_l":  '/images/projects/mens-club/video.MOV',
    "slider": []
    // "slider": ['/images/projects/mens-club/slider.HEIC', '/images/projects/mens-club/slider2.HEIC', '/images/projects/mens-club/slider3.HEIC', '/images/projects/mens-club/slider4.HEIC','/images/projects/mens-club/slider5.HEIC']
  },
  {
    "id": 64,
    "slug": 'NDMV',
    "feature": 0,
    "mainImage": "/images/projects/goodgirlLA/main.jpg",
    "blackImage": "/images/projects/goodgirlLA/main.jpg",
    "setDesignSketche": [],
    "behindTheScene": ["/images/projects/goodgirlLA/bts.jpg", "/images/projects/goodgirlLA/bts2.JPG"],
    "name": "No Diet Music Video",
    "category": "Music Video",
    "production_year": 2021,
    "client": "Goodgirl La",
    "role": "Production Designer & Art Director",
    "video_link": "",
    "video_l": "",
    "slider": ["/images/projects/goodgirlLA/slider.JPG","/images/projects/goodgirlLA/slider2.jpg","/images/projects/goodgirlLA/slider3.jpg","/images/projects/goodgirlLA/slider4.jpg","/images/projects/goodgirlLA/slider5.JPG","/images/projects/goodgirlLA/slider6.jpg", "/images/projects/goodgirlLA/slider7.jpg"]
  },
  {
    "id": 65,
    "slug": 'BTT1',
    "feature": 0,
    "mainImage": '/images/projects/becoming/Poster.jpg',
    "blackImage": '/images/projects/becoming/Poster.jpg',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/becoming/BTS1.jpeg', '/images/projects/becoming/BTS2.jpeg'],
    "name": "Becoming The Turner Season 1",
    "category": "Web Series",
    "production_year": 2021,
    "client": "Bukky Oloyede Films",
    "role": "Production Designer & Art Director",
    "video_link": '/images/projects/becoming/video.mp4',
    "video_l": '',
    "slider": ['/images/projects/becoming/slides1.jpg', '/images/projects/becoming/slides2.jpeg', '/images/projects/becoming/slides3.jpg']
  },
  {
    "id": 66,
    "slug": 'BTT2',
    "feature": 0,
    "mainImage": '/images/projects/becoming/coloured.png',
    "blackImage": '/images/projects/becoming/black.png',
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "Becoming The Turner Season 2",
    "category": "Web Series",
    "video_link": '/images/projects/becoming/video.mp4',
    "video_l": '',
    "slider": ['/images/projects/becoming/slides1.png', '/images/projects/becoming/slides2.jpeg', '/images/projects/becoming/slides3.png']
  },
  {
    "id": 67,
    "slug": 'Bigi',
    "feature": 0,
    "mainImage": '/images/projects/bigibet/bigi.JPG',
    "blackImage": '/images/projects/bigibet/bigi.JPG',
    "setDesignSketche": [],
    "behindTheScene": ['/images/projects/bigibet/Bts1.png','/images/projects/bigibet/Bts2.png',],
    "name": "Bigibet Commercial",
    "category": "Commercial",
    "production_year": 2021,
    "client": "Bigi Bet",
    "role": "Production Designer & Art Director",
    "video_link": '/images/projects/bigibet/video.mp4',
    "video_l": '',
    "slider": []
  },

  {
    "id": 69,
    "slug": '3DDESIGNCHURCH',
    "feature": 0,
    "mainImage": "/images/projects/3DchurchTv/main.jpg",
    "blackImage": "/images/projects/3DchurchTv/main.jpg",
    "setDesignSketche": ["/images/projects/3DchurchTv/sk.jpg", "/images/projects/3DchurchTv/sk2.jpg"],
    "behindTheScene": [],
    "name": "3D DESIGN FOR CHURCH TV SHOW",
    "category": "3D DESIGN",
    "production_year": 2023,
    "client": "",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": ["/images/projects/3DchurchTv/slider.jpg","/images/projects/3DchurchTv/slider2.jpg","/images/projects/3DchurchTv/slider3.jpg","/images/projects/3DchurchTv/slider4.jpg","/images/projects/3DchurchTv/slider5.jpg",]
  },
  {
    "id": 70,
    "slug": '3DDESIGNAGLICAN',
    "feature": 0,
    "mainImage": "/images/projects/3DchurchAnglican/sk.JPG",
    "blackImage": "/images/projects/3DchurchAnglican/sk.JPG",
    "setDesignSketche": ["/images/projects/3DchurchAnglican/sk.JPG", "/images/projects/3DchurchAnglican/sk2.JPG"],
    "behindTheScene": [],
    "name": "3D DESIGN FOR ANGLICAN PESTICOSTAL",
    "category": "3D DESIGN",
    "production_year": 2023,
    "client": "",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": [ "/images/projects/3DchurchAnglican/slide.JPG", "/images/projects/3DchurchAnglican/slide2.JPG", "/images/projects/3DchurchAnglican/slide3.JPG", "/images/projects/3DchurchAnglican/slide4.JPG", "/images/projects/3DchurchAnglican/slider5.JPG", "/images/projects/3DchurchAnglican/slider6.JPG"]
  },

  {
    "id": 72,
    "slug": 'HORLAOFLAGOS',
    "feature": 0,
    "mainImage": "/images/projects/bgHarlourOfLagos/mm.jpg",
    "blackImage": "/images/projects/bgHarlourOfLagos/mm.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "BACKGROUND FOR HORLAOFLAGOS",
    "category": "Set Design",
    "production_year": 2023,
    "client": "HORLAOFLAGOS",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 73,
    "slug": 'CHILDROOM',
    "feature": 0,
    "mainImage": "/images/projects/Child-room/sk2.jpg",
    "blackImage": "/images/projects/Child-room/sk2.jpg",
    "setDesignSketche": ["/images/projects/Child-room/sk.jpg","/images/projects/Child-room/sk2.jpg"],
    "behindTheScene": [],
    "name": "CHILD ROOM DESIGN",
    "category": "3D DESIGN",
    "production_year": 2023,
    "client": "MISS IBUKUN",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": ["/images/projects/Child-room/slider.jpg","/images/projects/Child-room/slider2.jpg","/images/projects/Child-room/slider3.jpg","/images/projects/Child-room/slider4.jpg","/images/projects/Child-room/slider5.jpg","/images/projects/Child-room/slider6.jpg",]
  },
  {
    "id": 74,
    "slug": 'CHURCHPM',
    "feature": 0,
    "mainImage": "/images/projects/church-prayer/main.jpg",
    "blackImage": "/images/projects/church-prayer/main.jpg",
    "setDesignSketche": ["/images/projects/church-prayer/sk.jpg","/images/projects/church-prayer/sk2.jpg",],
    "behindTheScene": [],
    "name": "CHURCH PRAYER MEETING STAGE DESIGN ",
    "category": "3D DESIGN",
    "production_year": 2023,
    "client": "",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": ["/images/projects/church-prayer/slider.jpg","/images/projects/church-prayer/slider2.jpg","/images/projects/church-prayer/slider3.jpg","/images/projects/church-prayer/slider4.jpg",]
  },
  {
    "id": 75,
    "slug": 'CHURCHSHOW',
    "feature": 0,
    "mainImage": "/images/projects/church-show-stage/churchshow.jpg",
    "blackImage": "/images/projects/church-show-stage/churchshow.jpg",
    "setDesignSketche": ["/images/projects/church-show-stage/sk.jpg", "/images/projects/church-show-stage/sk2.jpg"],
    "behindTheScene": [],
    "name": "CHURCH SHOW STAGE DESIGN",
    "category": "3D DESIGN",
    "production_year": 2023,
    "client": "DR KAFFY",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": ["/images/projects/church-show-stage/slider.jpg","/images/projects/church-show-stage/slider2.jpg","/images/projects/church-show-stage/slider3.jpg","/images/projects/church-show-stage/slider4.jpg","/images/projects/church-show-stage/slider5.jpg"]
  },
  {
    "id": 76,
    "slug": 'HAIRDRESSERS',
    "feature": 0,
    "mainImage": "/images/projects/hairdressers/display.jpg",
    "blackImage": "/images/projects/hairdressers/display.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "HAIRDRESSERS OR HARARE DESIGN ",
    "category": "Set Design",
    "production_year": 2023,
    "client": "",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": ["/images/projects/hairdressers/color.jpg"]
  },

  {
    "id": 78,
    "slug": 'MOTHER&CHILD',
    "feature": 0,
    "mainImage": "/images/projects/Store/mother.jpg",
    "blackImage": "/images/projects/Store/mother.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "MOTHER & CHILD TV SHOW",
    "category": "3D DESIGN",
    "production_year": 2023,
    "client": "",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 79,
    "slug": 'Oxlade',
    "feature": 0,
    "mainImage": "/images/projects/CoverOxlade/main.JPG",
    "blackImage": "/images/projects/CoverOxlade/main.JPG",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "COVER FOR OXLADE",
    "category": "MAGAZINE",
    "production_year": 2023,
    "client": "OXLADE",
    "role": "ART DIRECTOR",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 80,
    "slug": 'Victony',
    "feature": 0,
    "mainImage": "/images/projects/CoverVictory/main.JPG",
    "blackImage": "/images/projects/CoverVictory/main2.JPG",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "COVER FOR VICTONY",
    "category": "MAGAZINE",
    "production_year": 2023,
    "client": "VICTONY",
    "role": "ART DIRECTOR",
    "video_link": "",
    "video_l": "",
    "slider": ["/images/projects/CoverVictory/slider.JPG","/images/projects/CoverVictory/slider2.JPG","/images/projects/CoverVictory/slider3.JPG","/images/projects/CoverVictory/slider4.JPG","/images/projects/CoverVictory/slider5.JPG",]
  },

  {
    "id": 82,
    "slug": 'EGFM',
    "feature": 0,
    "mainImage": "/images/projects/egfm/main.jpg",
    "blackImage": "/images/projects/egfm/main2.jpg",
    "setDesignSketche": ["/images/projects/egfm/sk.jpg","/images/projects/egfm/sk2.jpg",],
    "behindTheScene": ["/images/projects/egfm/bts.jpg",],
    "name": "EGFM BECON23 ",
    "category": "STAGE DESIGN",
    "production_year": 2023,
    "client": "EGFM(ETERNAL GLORIOUS FOUNTAIN MINISTRY)",
    "role": "PRODUCTION DESIGNER & ART DIRECTOR",
    "video_link": "",
    "video_l": "",
    "slider": ["/images/projects/egfm/main2.jpg","/images/projects/egfm/slider.jpg","/images/projects/egfm/slider2.jpg","/images/projects/egfm/slider3.jpg","/images/projects/egfm/slider4.jpg",]
  },
  {
    "id": 83,
    "slug": 'HORLAOFLAGOSBRD',
    "feature": 0,
    "mainImage": "/images/projects/harloaroflagosbdady/main.JPG",
    "blackImage": "/images/projects/harloaroflagosbdady/main.JPG",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "HORLAOFLAGOS BIRTHDAY SHOOT",
    "category": "PHOTOSHOOT SET DESIGNS",
    "production_year": 2023,
    "client": "HORLAOFLAGOS",
    "role": "ART DIRECTOR",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 84,
    "slug": 'HORLAOFLAGOSBACKDROP',
    "feature": 0,
    "mainImage": "/images/projects/Store/tvbackdrop.JPG",
    "blackImage": "/images/projects/Store/tvbackdrop.JPG",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "TV ON BACKDROP FOR HORLAOFLAGOS",
    "category": "PHOTOSHOOT SET DESIGNS",
    "production_year": 2023,
    "client": "HORLAOFLAGOS",
    "role": "ART DIRECTOR",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 85,
    "slug": 'PRISONSET',
    "feature": 0,
    "mainImage": "/images/projects/prison-set/bts.jpg",
    "blackImage": "/images/projects/prison-set/bts.jpg",
    "setDesignSketche": ["/images/projects/prison-set/bts2.jpg","/images/projects/prison-set/bts.jpg",],
    "behindTheScene": [],
    "name": "PRISON SET BUILD",
    "category": "SET DESIGNS",
    "production_year": 2023,
    "client": "",
    "role": "PRODUCTION DESIGNER & ART DIRECTOR",
    "video_link": "/images/projects/prison-set/video.mp4",
    "video_l": "/images/projects/prison-set/new.mp4",
    "slider": []
  },
  {
    "id": 86,
    "slug": 'WEBMERRYMEN',
    "feature": 0,
    "mainImage": "/images/projects/merry-men/Merrymen.jpg",
    "blackImage": "/images/projects/merry-men/Merrymen.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "WEB DESIGN FOR MERRYMEN",
    "category": "SET DESIGNS",
    "production_year": 2023,
    "client": " COOPERATE WORLD ENTERTAINMENT & FILMONE",
    "role": "",
    "video_link": "/images/projects/merry-men/video.mp4",
    "video_l": "",
    "slider": ["/images/projects/merry-men/slider.JPG", "/images/projects/merry-men/slider2.JPG", "/images/projects/merry-men/slider3.JPG", "/images/projects/merry-men/slider4.JPG", "/images/projects/merry-men/slider5.JPG"]
  },
  {
    "id": 87,
    "slug": 'ABOBOYAA',
    "feature": 0,
    "mainImage": "/images/projects/Lock-up/Color.jpeg",
    "blackImage": "/images/projects/Lock-up/Color.jpeg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "ABOBOYAA(POPCAAN FT BURNA BOY)",
    "category": "MUSIC VIDEO ",
    "production_year": 2023,
    "client": "",
    "role": "",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 88,
    "slug": 'SPACEC',
    "feature": 0,
    "mainImage": "/images/projects/Store/d33d.jpg",
    "blackImage": "/images/projects/Store/d33d.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "SPACE CONVERSION",
    "category": "PHOTOGRAPHY",
    "production_year": 2021,
    "client": "",
    "role": "SET DESIGNER & SET DRESSER",
    "video_link": "",
    "video_l": "",
    "slider": []
  },
  {
    "id": 89,
    "slug": 'WOODMARKET',
    "feature": 0,
    "mainImage": "/images/projects/tthw/trip.jpg",
    "blackImage": "/images/projects/tthw/trip.jpg",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "TRIP TO THE WOOD MARKET AND TEACHING STUDENTS ON WOOD ",
    "category": "SPECIALS",
    "production_year": 2022,
    "client": "EBONY LIFE ART DIRECTION",
    "role": "SET DESIGNER & SET DRESSER",
    "video_link": "/images/projects/tthw/video.mp4", 
    "video_l": "",
    "slider": []
  },
  {
    "id": 90,
    "slug": 'COMMUNE',
    "feature": 0,
    "mainImage": "/images/projects/Store/Artist.PNG",
    "blackImage": "/images/projects/Store/Artist.PNG",
    "setDesignSketche": [],
    "behindTheScene": [],
    "name": "THE ARTIST COMMUNE",
    "category": "ART EXHIBITION",
    "production_year": 2022,
    "client": "B.I.G MOSAAD",
    "role": "ART DIRECTOR",
    "video_link": "",
    "video_l": "",
    "slider": []
  }
];
// export default projects;
// [
//   {
//     "Portfolio Title": "Trillion Dollar Dream",
//     "Categories": "Music Video",
//     "Production Year": 2023,
//     "Client": "Ibeji/Xtrim Studio",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Chocolate Hour",
//     "Categories": "Music Video",
//     "Production Year": 2023,
//     "Client": "Ibeji/Xtrim Studio",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Pink Pineapple",
//     "Categories": "Music Video",
//     "Production Year": 2023,
//     "Client": "Ibeji/Xtrim Studio",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Lock Up By Nonso Amandi Ft Zinoleesky",
//     "Categories": "Music Video",
//     "Production Year": 2023,
//     "Client": "Uaxstudio",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1S_oHtYRCK-VQThB0T3OOQq8PDxFqrMC0?usp=drive_link"
//   },
//   {
//     "Portfolio Title": "Sony Cine Tour",
//     "Categories": "Set Design",
//     "Production Year": 2023,
//     "Client": "Sony",
//     "Role Of Thlphbt": "Production Designer And Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1SUR_RA0ljD0TYJa1-iT7-Nx8js9z0YiQ?usp=drive_link"
//   },
//   {
//     "Portfolio Title": "Hackathon",
//     "Categories": "Event Design",
//     "Production Year": 2023,
//     "Client": "Ilorin Innovation Hub And The Governor Of Ilorin",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1Nw15vEv7UT8xM6ixD2_GAplUPQmTsT7y?usp=drive_link"
//   },
//   {
//     "Portfolio Title": "Betensured Commerical",
//     "Categories": "Commercial",
//     "Production Year": 2023,
//     "Client": "Krex Studios",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1SdyVUKtnaqsyteZ-w8fcRoFU_HDLDX7F?usp=drive_link"
//   },
//   {
//     "Portfolio Title": "Viva Branding For Nancy Isime",
//     "Categories": "Tv Show",
//     "Production Year": 2023,
//     "Client": "Expose Brand",
//     "Role Of Thlphbt": "Branding Agent",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Merry Men 3",
//     "Categories": "Feature Lenght Film",
//     "Production Year": 2023,
//     "Client": "Cooperate World Entertainment & Filmone",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://www.instagram.com/reel/Cmxb1rbh3Rj/?utm_source=ig_web_copy_link"
//   },
//   {
//     "Portfolio Title": "Sugar Rush 2",
//     "Categories": "Feature Lenght Film",
//     "Production Year": 2023,
//     "Client": "Greoh Studio",
//     "Role Of Thlphbt": "2nd Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Flawsome",
//     "Categories": "Web Series",
//     "Production Year": 2022,
//     "Client": "Showmax Original",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Jossyann Music Video",
//     "Categories": "Music Video",
//     "Production Year": 2022,
//     "Client": "Sisi Eko Jossyann",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Podcast Studio Design",
//     "Categories": "Set Design For Podcast",
//     "Production Year": 2022,
//     "Client": "Josh Olawole",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Theivvyhouse Fashion Shoot",
//     "Categories": "Fashion Shoot",
//     "Production Year": 2022,
//     "Client": "The Ivvy House",
//     "Role Of Thlphbt": "Production Designer, Creative Director & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Lareto Fashion Stagerunway",
//     "Categories": "Fashion Shoot",
//     "Production Year": 2022,
//     "Client": "Urban Vision 360",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Chizzy Online Music Video",
//     "Categories": "Music Video",
//     "Production Year": 2022,
//     "Client": "Ifeme Cs",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "All Or Nothing",
//     "Categories": "Feature Length Film",
//     "Production Year": 2022,
//     "Client": "King Views Production & Picado",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1T7T0vSO2omd2Tb_chu7K6MQh3iG5Vg8M"
//   },
//   {
//     "Portfolio Title": "Grey Velvet Christmas Set Design",
//     "Categories": "Set Design For A Fashion Shoot",
//     "Production Year": 2022,
//     "Client": "Grey Velvet Store",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Amvca Photoboot( African Magic Viewers Choice Awards",
//     "Categories": "Set Build For Photoshoot The Amvca Awards",
//     "Production Year": 2022,
//     "Client": "Kola Osalusi",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1StvuhgBhWdZr7GOoOmBWsZDOxm7olv4s?usp=sharing"
//   },
//   {
//     "Portfolio Title": "Lafiya Telehealth Commercial( English)",
//     "Categories": "Commercial",
//     "Production Year": 2022,
//     "Client": "Lucy Ameh",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Lafiya Telehealth Commercial( Pidgin)",
//     "Categories": "Commercial",
//     "Production Year": 2022,
//     "Client": "Lucy Ameh",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Lafiya Telehealth Commercial (Hausa)",
//     "Categories": "Commercial",
//     "Production Year": 2022,
//     "Client": "Lucy Ameh",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Ini The Musical",
//     "Categories": "Stage Play",
//     "Production Year": 2022,
//     "Client": "Sisiano",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1SxLBJf5sOB_Qt60Sgs9xxWojD7wr_taq"
//   },
//   {
//     "Portfolio Title": "Loya Milk Commercial (English )",
//     "Categories": "Commercial",
//     "Production Year": 2022,
//     "Client": "Promacidor",
//     "Role Of Thlphbt": "Set Dresser & Property Manager",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Loya Milk Commercial (Hausa)",
//     "Categories": "Commercial",
//     "Production Year": 2022,
//     "Client": "Promacidor",
//     "Role Of Thlphbt": "Set Dresser & Property Manager",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Teens Bible Boot Camp",
//     "Categories": "Stage Design",
//     "Production Year": 2022,
//     "Client": "Nlwc( New And Living Way Church)",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Stage Design For Nlwc",
//     "Categories": "Set Design",
//     "Production Year": 2022,
//     "Client": "Nlwc( New And Living Way Church)",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1faRd3I7Yo8HFNP8h--KKHtJa4lYL7O6F"
//   },
//   {
//     "Portfolio Title": "Moment Of Truth",
//     "Categories": "Tv Show",
//     "Production Year": 2022,
//     "Client": "Mr Dare Johnson",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1SVz3XqLUwBFjRAL8LwNfnelyEFGCwOYh"
//   },
//   {
//     "Portfolio Title": "Big Brother Naija; Meet The Housemate",
//     "Categories": "Tv Show",
//     "Production Year": 2022,
//     "Client": "Alo 360 Media",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1hKvjGeJXhaEf49L_PKfFPiC-haSRL60V"
//   },
//   {
//     "Portfolio Title": "The Standup",
//     "Categories": "Feature Film",
//     "Production Year": 2022,
//     "Client": "King Views Production",
//     "Role Of Thlphbt": "Property Master",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "G.O.L By High Fashion",
//     "Categories": "Fashion Shoot",
//     "Production Year": 2022,
//     "Client": "Octalayke For Rahman Jago",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Meadow School Stage Drama",
//     "Categories": "Stage Set Design For Stage Drama",
//     "Production Year": 2022,
//     "Client": "Meadow School",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Egfm Becon Interview Shoot",
//     "Categories": "Commercial",
//     "Production Year": 2022,
//     "Client": "Egfm(Eternal Glorious Fountain Ministry)",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Mtn X Youtube",
//     "Categories": "Commercial",
//     "Production Year": 2022,
//     "Client": "Mtn X Youtube",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1YIwYLRW-YzgZzxpNBdGtVVWBEoq4uaqL"
//   },
//   {
//     "Portfolio Title": "Mskd And Sptr",
//     "Categories": "Art Exhibition",
//     "Production Year": 2022,
//     "Client": "Horlaoflagos",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Unlocked For Bbn",
//     "Categories": "Tv Show",
//     "Production Year": 2022,
//     "Client": "Alo 360 Media",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1SpqG54TuWaS5puRw6fqwPZzvx25V8wt_"
//   },
//   {
//     "Portfolio Title": "Rebook Camp Set Design",
//     "Categories": "Tv Show",
//     "Production Year": 2022,
//     "Client": "Celebration Church International",
//     "Role Of Thlphbt": "2nd Art Director & Set Dresser",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1TFa1IwhuR6LduTxxWLwNtcu72b_FfO02"
//   },
//   {
//     "Portfolio Title": "Zabin Halim",
//     "Categories": "Short Film",
//     "Production Year": 2022,
//     "Client": "Netflix And Unesco",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Love Doctor",
//     "Categories": "Tv Show",
//     "Production Year": 2022,
//     "Client": "Ckay Show",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "A Class With Hannah Bleacher",
//     "Categories": "A Training",
//     "Production Year": 2022,
//     "Client": "Production Designer Of Blank Panter",
//     "Role Of Thlphbt": "A Student",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Adieu Salut",
//     "Categories": "Short Film",
//     "Production Year": 2022,
//     "Client": "Netflix And Unesco",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "A Billion Soul's Documentary",
//     "Categories": "Documentary",
//     "Production Year": 2022,
//     "Client": "Celebration Church International",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1T42eUTBhuYKdawB_kLpOZ7TCCjLrvKuM"
//   },
//   {
//     "Portfolio Title": "Elevation Studio Set Design",
//     "Categories": "Set Design",
//     "Production Year": 2022,
//     "Client": "Elevation Church",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Blue Nun Commercial",
//     "Categories": "Commercial",
//     "Production Year": 2022,
//     "Client": "Mr Oladele Onasanya",
//     "Role Of Thlphbt": "Asst. Art Director, Property Manager & Ser Dresser",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Zeus Show Your Working Music Video Shoot",
//     "Categories": "Music Video",
//     "Production Year": 2022,
//     "Client": "Zeus",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Kristipedia",
//     "Categories": "Feature Film",
//     "Production Year": 2022,
//     "Client": "Marauders",
//     "Role Of Thlphbt": "Production Designer",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "The Manipulated Man",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Ebony Life Creative Academy",
//     "Role Of Thlphbt": "Set Dresser & Property Master",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Self",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Ebony Life Creative Academy",
//     "Role Of Thlphbt": "Set Dresser & Property Master",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Dilemma",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Ebony Life Creative Academy",
//     "Role Of Thlphbt": "Property Master",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1TIp-0N1ujvOhhqyjaGHlLfNSCzKpX6xI"
//   },
//   {
//     "Portfolio Title": "Ifeloju",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Ebony Life Creative Academy",
//     "Role Of Thlphbt": "Set Dresser & Property Master",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1cb59N-nMbtnKXpNWggWWCz3qMzKQ_9KI"
//   },
//   {
//     "Portfolio Title": "Fury",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Ebony Life Creative Academy",
//     "Role Of Thlphbt": "Set Dresser & Property Master",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Monitoring Spirit",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Afriff Selection",
//     "Role Of Thlphbt": "Set Dresser",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "The Next Best Thing",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Ebony Life Creative Academy",
//     "Role Of Thlphbt": "Production Designer And Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Generation Next",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Mun Media",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Trash Monster",
//     "Categories": "Stop Motion Film",
//     "Production Year": 2021,
//     "Client": "Art Cast Tribe",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1gqLxZ4fGPmMp4e3mF3-iY-OMpjLRmNYj"
//   },
//   {
//     "Portfolio Title": "Heir Insurance Commercial ( Tenancy)",
//     "Categories": "Commercial",
//     "Production Year": 2021,
//     "Client": "Heir Insurance( Tony Onumelu)",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Heir Insurance Commercial ( Motor Insurance)",
//     "Categories": "Commercial",
//     "Production Year": 2021,
//     "Client": "Heir Insurance( Tony Onumelu)",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Nostalgia",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Naomi Rhodas & Titofunmi",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "The Way Out",
//     "Categories": "Silent Film",
//     "Production Year": 2021,
//     "Client": "Ebony Life Creative Academy",
//     "Role Of Thlphbt": "Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Wait Music Video",
//     "Categories": "Music Video",
//     "Production Year": 2021,
//     "Client": "Sammy Lee- Music Director & Ifeme Cs - Producer",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Family Meeting",
//     "Categories": "Short Film",
//     "Production Year": 2021,
//     "Client": "Ulo Aku",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "This Thing Called Love",
//     "Categories": "Web Series",
//     "Production Year": 2021,
//     "Client": "Gems Studio",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "The Men's Club Season 4",
//     "Categories": "Web Series",
//     "Production Year": 2021,
//     "Client": "Urban Vision",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "No Diet Music Video",
//     "Categories": "Music Video",
//     "Production Year": 2021,
//     "Client": "Goodgirl La",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Becoming The Turner Season 1",
//     "Categories": "Web Series",
//     "Production Year": 2021,
//     "Client": "Bukky Oloyede Films",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1TAQroEGdRniGifJdwo4R1yLqTBuI38lo"
//   },
//   {
//     "Portfolio Title": "Becoming The Turner Season 2",
//     "Categories": "",
//     "Production Year": "",
//     "Client": "",
//     "Role Of Thlphbt": "",
//     "Link to BTS/ the main Video": ""
//   },
//   {
//     "Portfolio Title": "Bigibet Commercial",
//     "Categories": "Commercial",
//     "Production Year": 2021,
//     "Client": "Bigi Bet",
//     "Role Of Thlphbt": "Production Designer & Art Director",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1TAA-AqZ0b-QGyD-rQHzIEvxcOTT_ONsM?usp=sharing"
//   },
//   {
//     "Portfolio Title": "Gangs Of Lagos",
//     "Categories": "Feature Film",
//     "Production Year": 2021,
//     "Client": "Mr Gbenga ( Greoh Studio)",
//     "Role Of Thlphbt": "Set Dresser & Property Master",
//     "Link to BTS/ the main Video": "https://drive.google.com/drive/folders/1T7aJtUI3bqkUmWIT7MqYSUsGuDiTKSl0"
//   }
// ]