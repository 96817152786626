import React, { useEffect, useState, useRef } from 'react'
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import emailjs from '@emailjs/browser';
import { ContactButton, ContactButtonDiv, ContactDivSpace, ContactInput, ContactInputDiv, ContactInputForm, ContactLabel, ContactTextArea, ErrorMessageCon, ModalContactWord, SpinnerDiv, SucessMessageCon } from './ContactStyle';
import Spinner from '../Spinner/Spinner';


const ContactModal = ({ visible, setVisible }) => {
    const isMobile = window.innerWidth <= 575;
    const [width, setWidth] = useState(500)

    const form = useRef();
    const [loading, setLoading] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);

    const hide = () => {
        setVisible(false);
    }
    useEffect(() => {
        if (isMobile) {
            setWidth(300)
        } else {
            setWidth(500)
        }

    }, [isMobile])

    const modalStyle = {
        top: '-150px', // Adjust the value to your desired distance from the top
        overflow: 'auto',
    };
    const modalStyleMain = {
        overflow: 'auto',
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        emailjs.sendForm('service_hw08be1', 'template_uex76am', form.current, 'lZ3dNuTDYFxpas-TP')
            .then((result) => {
                setSubmissionSuccess(true);
                setSubmissionError(false);
                setLoading(false);
                setTimeout(() => {
                    setSubmissionSuccess(false)
                }, 5000);
                setTimeout(() => {
                    form.current.reset()
                }, 3000);
            }, (error) => {
                setLoading(false);
                setSubmissionError(true)
                console.log(error.text);
                setTimeout(() => {
                    setSubmissionError(false)
                }, 5000);
            });

    }

    return (
        <div>

            <Rodal visible={visible} onClose={hide} width={width} height={650} customStyles={isMobile ? modalStyle : modalStyleMain}>
                <ModalContactWord>Ready to create a masterpiece?</ModalContactWord>
                <ModalContactWord red>We’re here to help.</ModalContactWord>
                {
                    loading ?
                        <SpinnerDiv>
                            <Spinner />
                        </SpinnerDiv>
                        :
                        <ContactInputForm modal ref={form} onSubmit={sendEmail}>
                            {
                                submissionSuccess &&
                                <SucessMessageCon>Message Sent Successfully</SucessMessageCon>
                            }
                            {
                                submissionError &&
                                <ErrorMessageCon>Couldn't send message please try again</ErrorMessageCon>
                            }
                            <ContactDivSpace>
                                <ContactInputDiv modal>
                                    <ContactLabel modal>Name</ContactLabel>
                                    <ContactInput modal type="text" name="name" required />
                                </ContactInputDiv>
                                <ContactInputDiv modal>
                                    <ContactLabel modal>Mobile Number</ContactLabel>
                                    <ContactInput modal type="text" name="phone" required />
                                </ContactInputDiv>
                            </ContactDivSpace>
                            <ContactDivSpace >
                                <ContactInputDiv modal>
                                    <ContactLabel modal>Email address</ContactLabel>
                                    <ContactInput modal type="text" name="email" required />
                                </ContactInputDiv>
                                <ContactInputDiv modal>
                                    <ContactLabel modal>Company</ContactLabel>
                                    <ContactInput modal type="text" name="company" />
                                </ContactInputDiv>
                            </ContactDivSpace>
                            <ContactDivSpace >
                                
                                <ContactInputDiv modal>
                                    <ContactLabel modal>Link &nbsp;</ContactLabel>
                                    <ContactInput modal type="text" name="link" />
                                </ContactInputDiv>
                            </ContactDivSpace>
                            <ContactInputDiv modal>
                                <ContactLabel modal>Tell us about your project</ContactLabel>
                                <ContactTextArea modal name="message" required />
                            </ContactInputDiv>
                            <ContactButtonDiv modal>
                                <ContactButton modal type='submit'>SEND</ContactButton>
                            </ContactButtonDiv>
                        </ContactInputForm>
                }

            </Rodal>
        </div>
    )
}

export default ContactModal