import React, { useState } from 'react'
import { SortWrapper, SortIcon, SearchInput, SearchWrapper, SearchIcon, SortPTag, PortfolioBreadCrumbWrap, PortfolioBreadFirstP, PortfolioBreadSecondP, PortfolioListWrapper, SortDescription, RadioCheck, RadioCheckInside, RadioLabel, PortfolioContactP, PortFolioContactUsLink, OpenFilterWrapper } from './portfolio.style'
import { Divider, IconButton, Stack, useMediaQuery } from '@mui/material'
import { FlexDiv, ReturnTopBtnDiv, ReturnTopBtnIcon } from '../../styled-component/MainComponent'
import { List, ListItem } from "./portfolio.style"
// import { PortfolioCardWrapper } from '../Card/productCardStyle'
import MobileModal from './MobileModal'
import { projects } from '../../Utils/project'
import Portfolio from './Portfolio'
import { useEffect } from 'react'
const ListPortfolio = () => {
    const isMobile = useMediaQuery('(max-width:575px)');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [OpenFilter, setOpenFilter] = useState(true);
    const categories = [...new Set(projects.map(project => project.category))].sort();
    const [DisplayProjects, setDisplayProjects] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [open, setOpen] = React.useState(false);

    //handle the category Sorting
    const handleCategorySelection = (cat) => {
        if (cat === 'All') {
            setDisplayProjects(projects);
        } else {
            const catFilterProjects = projects.filter((project) => project.category === cat);
            setDisplayProjects(catFilterProjects);
        }

        setSelectedCategory(cat);
        setOpen(false);
    };

    //handle search 
    const handleSearchInputChange = (event) => {
        const inputValue = event.target.value;
        setSearchText(inputValue);
        console.log(inputValue);
        // You can also call the filtering function here passing 'inputValue' as an argument.
        const filteredProjects = filterProjectsByName(inputValue);
        console.log(filteredProjects);
        setDisplayProjects(filteredProjects)
    };

    //filter by name
    const filterProjectsByName = (searchText) => {
        const searchTerm = searchText.toLowerCase().trim();

        const filteredProjects = projects.filter((project) => {
            const projectName = project.name.toLowerCase();
            return projectName.includes(searchTerm);
        });

        return filteredProjects;
    };



    const HandleOpenFilter = () => {
        setOpenFilter(current => !current);
    }
    const HandleCloseFilter = () => {
        setOpenFilter(false);
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        setDisplayProjects(projects);
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <SortWrapper>
                {
                    isMobile
                        ?
                        <MobileModal {...{ categories, handleCategorySelection, selectedCategory, open, setOpen }} />
                        :
                        <div style={{ display: 'flex' }}>
                            <IconButton onClick={HandleOpenFilter}>
                                <SortIcon src='images/icons/filter.png' />
                            </IconButton>
                            <SortPTag style={{ color: '#272727' }}>Filter</SortPTag>
                        </div>
                }

                <SearchWrapper>
                    <SearchInput placeholder='Search' id='search' name='search' autoComplete="off"  value={searchText} onChange={handleSearchInputChange} />
                    <SearchIcon src='images/icons/search.png' />
                </SearchWrapper>
            </SortWrapper>
            <Divider sx={{ margin: "1.5rem 0rem" }} />

            <PortfolioBreadCrumbWrap>
                <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                >
                    <PortfolioBreadFirstP>portfolio</PortfolioBreadFirstP>
                    <PortfolioBreadSecondP>/ {selectedCategory}</PortfolioBreadSecondP>

                </Stack>
            </PortfolioBreadCrumbWrap>

            <PortfolioListWrapper>
                
                    
               <FlexDiv>
                    {
                        OpenFilter &&
                        (
                            <OpenFilterWrapper>
                                <FlexDiv style={{ gap: '7rem' }}>
                                    <FlexDiv>
                                        <IconButton onClick={HandleOpenFilter}>
                                            <SortIcon src='images/icons/filter.png' />
                                        </IconButton>
                                        <SortPTag style={{ color: '#272727' }}>Filter</SortPTag>
                                    </FlexDiv>

                                    <IconButton onClick={HandleCloseFilter}>
                                        <SortIcon src='images/icons/cancel.png' />
                                    </IconButton>
                                </FlexDiv>
                                <SortDescription>Sort projects by Categories</SortDescription>
                                <List>
                                    <ListItem >
                                        <IconButton onClick={(e) => handleCategorySelection('All')}>
                                            <RadioCheck checked={selectedCategory === 'All'}>
                                                <RadioCheckInside checked={selectedCategory === 'All'} />
                                            </RadioCheck>
                                        </IconButton>
                                        <RadioLabel>{'All'}</RadioLabel>
                                    </ListItem>
                                    {
                                        categories.map((cat, index) => (
                                            <ListItem key={index}>
                                                <IconButton onClick={(e) => handleCategorySelection(cat)}>
                                                    <RadioCheck checked={selectedCategory === cat}>
                                                        <RadioCheckInside checked={selectedCategory === cat} />
                                                    </RadioCheck>
                                                </IconButton>
                                                <RadioLabel>{cat}</RadioLabel>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </OpenFilterWrapper>
                        )

                    }
                    <Portfolio projects={DisplayProjects} OpenFilter={OpenFilter} />
                </FlexDiv>
              
                {/* <Stack
                    direction={'row'}
                    spacing={{
                        md: 10,
                        l: 20,
                        sm: 10,
                        xs: 4,
                    }}
                    alignItems={"center"}
                >
                    {
                        OpenFilter &&
                        (
                            <OpenFilterWrapper>
                                <FlexDiv style={{ gap: '7rem' }}>
                                    <FlexDiv>
                                        <IconButton onClick={HandleOpenFilter}>
                                            <SortIcon src='images/icons/filter.png' />
                                        </IconButton>
                                        <SortPTag style={{ color: '#272727' }}>Filter</SortPTag>
                                    </FlexDiv>

                                    <IconButton onClick={HandleCloseFilter}>
                                        <SortIcon src='images/icons/cancel.png' />
                                    </IconButton>
                                </FlexDiv>
                                <SortDescription>Sort projects by Categories</SortDescription>
                                <List>
                                    <ListItem >
                                        <IconButton onClick={(e) => handleCategorySelection('All')}>
                                            <RadioCheck checked={selectedCategory === 'All'}>
                                                <RadioCheckInside checked={selectedCategory === 'All'} />
                                            </RadioCheck>
                                        </IconButton>
                                        <RadioLabel>{'All'}</RadioLabel>
                                    </ListItem>
                                    {
                                        categories.map((cat, index) => (
                                            <ListItem key={index}>
                                                <IconButton onClick={(e) => handleCategorySelection(cat)}>
                                                    <RadioCheck checked={selectedCategory === cat}>
                                                        <RadioCheckInside checked={selectedCategory === cat} />
                                                    </RadioCheck>
                                                </IconButton>
                                                <RadioLabel>{cat}</RadioLabel>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </OpenFilterWrapper>
                        )

                    }
                    <Portfolio projects={DisplayProjects} OpenFilter={OpenFilter} />
                </Stack>  */}
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ margin: "8rem 0" }}
                >
                    <PortfolioContactP>Like what you see?</PortfolioContactP>
                    <PortFolioContactUsLink> CONTACT US </PortFolioContactUsLink>
                </Stack>

                {
                    !isMobile
                    &&
                    <ReturnTopBtnDiv>
                        <IconButton onClick={scrollToTop}>
                            <ReturnTopBtnIcon src='images/icons/backup.png' />
                        </IconButton>
                    </ReturnTopBtnDiv>
                }

            </PortfolioListWrapper>
        </div>
    )
}

export default ListPortfolio