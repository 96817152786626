import React from 'react'
import { AboutBannerSubTitle, AboutBannerTitle, AboutBannerWrapper } from './banner'
import { FlexDivCenter } from '../../styled-component/MainComponent'

const AboutBanner = () => {
    return (
        <AboutBannerWrapper>
            <AboutBannerTitle>
                About Us
            </AboutBannerTitle>
            <FlexDivCenter>
                <AboutBannerSubTitle>
                    THLPHBT is an award-winning designs company that provides top notch production design, art direction, interior/exterior designs, props rental as well as other creative services that brings you into the world of limitless creativity and visionary designs. We excel at crafting captivating motion pictures, unforgettable event designs across various genres. Explore the realm of boundless imagination and experience the extraordinary power of our artistic vision.
                </AboutBannerSubTitle>
            </FlexDivCenter>

        </AboutBannerWrapper>
    )
}

export default AboutBanner