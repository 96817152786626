import React, {useEffect} from 'react'
import PortfolioBanner from '../Components/Banner/PortfolioBanner'
import ListPortfolio from '../Components/Portfolio/ListPortfolio'


const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
        <PortfolioBanner />
        <ListPortfolio />
    </>
  )
}

export default Portfolio