import React, {useState} from 'react'
import {ServiceBannnerFlex, ServiceBannerTitle, ServiceBannerSubTitle, ServiceBannerWrapper, ServiceBannerButtonn, ServiceSecondDiv } from './banner'
import { FlexDivCenter } from '../../styled-component/MainComponent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContactModal from '../Contact/ContactModal';

const ServiceBanner = () => {
    const [visible, setVisible] = useState(false);
    const show = () => {
        setVisible(true);
    }
    return (
        <ServiceBannerWrapper>
            <ServiceBannnerFlex>
                <ServiceBannerTitle>
                    Elevate Your Vision, <br /> Craft Your Reality
                </ServiceBannerTitle>
                <ServiceSecondDiv>
                    <ServiceBannerSubTitle>
                        Whether you're working on a film, television show, commercial, event, or any other project, we have the expertise to make your ideas shine.
                    </ServiceBannerSubTitle>
                    <ServiceBannerButtonn onClick={show}>
                        GET IN TOUCH <ArrowForwardIcon sx={{ fontSize: '17px' }} />
                    </ServiceBannerButtonn>
                    <ContactModal visible={visible} setVisible={setVisible} />
                </ServiceSecondDiv>
                 

            </ServiceBannnerFlex>

        </ServiceBannerWrapper>
    )
}

export default ServiceBanner