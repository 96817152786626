import React from 'react'
import { CardBody, CardImageWrapper, CardInfo, CardInfoAImage, CardInfoATag, CardLinkWrapper, CardTitle } from './productCardStyle'
import { FlexDiv } from '../../styled-component/MainComponent'


const PortfolioProjectCard = ({ filter, project }) => {
    return (

        <CardBody portfolio={true}>
            <CardLinkWrapper to={`/portfoliodetails/${project.slug}`}>
                <CardImageWrapper imageSrc={project.mainImage} imageOld={project.blackImage}>
                </CardImageWrapper>
                <CardTitle>
                    {project.name}
                </CardTitle>
            </CardLinkWrapper>
        </CardBody>

    )
}

export default PortfolioProjectCard