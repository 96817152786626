import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import { MosardFlex, SectionBody } from '../../styled-component/MainComponent'
import { ContactButton, ContactButtonDiv, ContactInput, ContactInputDiv, ContactInputForm, ContactLabel, ContactMains, ContactTextArea, ContactWord, ContactWordMobile, ContactWordRed, ContactWordRedMobile, ContactWords2, ErrorMessageCon, SpinnerDiv, SucessMessageCon } from './ContactStyle'
import Spinner from '../Spinner/Spinner';

const Contact = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs.sendForm('service_hw08be1', 'template_uex76am', form.current, 'lZ3dNuTDYFxpas-TP')
      .then((result) => {
        setSubmissionSuccess(true);
        setSubmissionError(false);
        setLoading(false);
        setTimeout(() => {
          setSubmissionSuccess(false)
        }, 5000);
        setTimeout(() => {
          form.current.reset()
        }, 3000);
      }, (error) => {
        setLoading(false);
        setSubmissionError(true)
        console.log(error.text);
        setTimeout(() => {
          setSubmissionError(false)
        }, 5000);
      });
      
  }
  return (
    <SectionBody>
      <MosardFlex>
        <ContactMains>
          <ContactWord>Ready to create some magic? </ContactWord>
          <ContactWordRed>We’ve got the skills! </ContactWordRed>
          <ContactWordRed>Let’s team up. </ContactWordRed>
          <ContactWords2>Tell us more about  yourself and what you’ve got in mind</ContactWords2>
          <ContactWordMobile>Ready to create some magic together?</ContactWordMobile>
          <ContactWordRedMobile>Contact Us</ContactWordRedMobile>
        </ContactMains>
        <ContactMains>
          {
            loading ?
              <SpinnerDiv>
                <Spinner />
              </SpinnerDiv>
              :
              <ContactInputForm ref={form} onSubmit={sendEmail}>
                {
                  submissionSuccess &&
                  <SucessMessageCon>Message Sent Successfully</SucessMessageCon>
                }
                {
                  submissionError &&
                  <ErrorMessageCon>Couldn't send message please try again</ErrorMessageCon>
                }
          
                <ContactInputDiv>
                  <ContactLabel >Name</ContactLabel>
                  <ContactInput type="text" name="name" required />
                </ContactInputDiv>
                <ContactInputDiv>
                  <ContactLabel>Mobile Number</ContactLabel>
                  <ContactInput type="text" name="phone" required />
                </ContactInputDiv>
                <ContactInputDiv>
                  <ContactLabel>Email address</ContactLabel>
                  <ContactInput type="text" name="email" required />
                </ContactInputDiv>
                <ContactInputDiv>
                  <ContactLabel>Company</ContactLabel>
                  <ContactInput type="text" name="company" />
                </ContactInputDiv>
                <ContactInputDiv>
                  <ContactLabel>Link</ContactLabel>
                  <ContactInput type="text" name="link"  />
                </ContactInputDiv>
                <ContactInputDiv>
                  <ContactLabel>Tell us about your project</ContactLabel>
                  <ContactTextArea name="message" required />
                </ContactInputDiv>
                <ContactButtonDiv>
                  <ContactButton type='submit'>SEND</ContactButton>
                </ContactButtonDiv>
              </ContactInputForm>
          }


        </ContactMains>

      </MosardFlex>
    </SectionBody>
  )
}

export default Contact