import React, { useState, useEffect } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BackwardWapper, ForwardWapper, ProjectSliderIcon, ProjectSliderIconDiv, ProjectSliderImg, ProjectSliderWrapper } from './Slider';

const ProjectSlider = ({ images }) => {
    let [sliderImage, setSliderImage] = useState(0);
    useEffect(() => {
        let timeout = setTimeout(() => {
            if (sliderImage === (images.length - 1)) {
                setSliderImage(0);
            } else {
                setSliderImage(sliderImage + 1);
            }
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [sliderImage, images.length]);
    // const imagePath = `/images/${Images[sliderImage]}`; 
    const imagePath = images[sliderImage];

    const forWardClick = ()=>{
        if (sliderImage === (images.length - 1)) {
            setSliderImage(0);
        }else{
            setSliderImage(sliderImage + 1);
        }
        
    }
    const backWardClick = ()=>{
        if (sliderImage === 0) {
            setSliderImage(images.length - 1);
        }
        else{
            setSliderImage(sliderImage - 1);
        }
    }
    return (
        <>


            <ProjectSliderWrapper>
                <ProjectSliderImg src={imagePath} />
                <ProjectSliderIconDiv>
                    {
                        images.map((item, index) => (
                            <ProjectSliderIcon key={index} activeRev={index === sliderImage ? 'true' : 'false'} />
                        ))
                    }
                </ProjectSliderIconDiv>
                <BackwardWapper onClick={backWardClick}>
                    <ArrowBackIcon fontSize="large" sx={{ color: '#B41B00' }} />
                </BackwardWapper>
                <ForwardWapper onClick={forWardClick}>
                    <ArrowForwardIcon fontSize="large"
                        sx={{ color: '#B41B00' }} />
                </ForwardWapper>
            </ProjectSliderWrapper>
        </>
    )
}

export default ProjectSlider