import React from 'react'
import { ContentWrapper } from '../Content/Content';
import { OverTitle } from '../Overview/Overview';
import PortfolioProjectCard from '../../Card/PortfolioProjectCard';
import { CardWrapper2 } from '../../Card/productCardStyle';


const SimilarProject = ({ similar }) => {
    // console.log(similar);

    return (
        <ContentWrapper>
            <OverTitle>SEE OTHER PROJECTS</OverTitle>
            <CardWrapper2>
                {
                    similar && similar.length > 0 &&
                    similar.map((item) => (
                        <PortfolioProjectCard key={item.id} filter={false} project={item} />
                    ))
                }
            </CardWrapper2>

        </ContentWrapper>
    )
}

export default SimilarProject