import React from 'react'
import { SliderWordH1 } from '../Sliders/SliderStyles'
import { BannerWrapper } from '../Portfolio/portfolio.style'


const PortfolioBanner = () => {
  return (
    <BannerWrapper>
        <SliderWordH1>PortFolio</SliderWordH1>
    </BannerWrapper>
  )
}

export default PortfolioBanner