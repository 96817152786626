import React, { useState, useEffect } from 'react'
import HomeSlider from '../Components/Sliders/HomeSlider'
import { ArrowButton, AutoDiv, CenterButton, FlexDivSpace, MainButtonLink, MainHeaderTag, MainPTag, MosaidPlayImage, MosardAutoDiv, MosardButtonDiv, MosardFlex, MosardImg, MosardWord, MosardWordRed, SectionBody } from '../styled-component/MainComponent'
import { ExtentContactImage } from '../Components/Navigation/NavigationStyled'
import { CardInfoAImage } from '../Components/Card/productCardStyle'
import ProjectCard from '../Components/Card/ProjectCard'
import { BannerTitle, BannerWrapper, SlideImg, Slider, SlideTrack } from '../styled-component/BannerStyled'
import AllReviews from '../Components/Reviews/AllReviews'
import Contact from '../Components/Contact/Contact'
import { projects } from '../Utils/project'
import { Grid, useMediaQuery } from '@mui/material';

let moImage = ["mosard.png", "love-story.jpeg"];

const Main = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery('(max-width:575px)');
  //filter to get all the featured project
  const FeaturedProjects = projects.filter((project) => project.feature === 1);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % FeaturedProjects.length);
    }, 10000);

    return () => clearInterval(timer);
  }, [FeaturedProjects.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % FeaturedProjects.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + FeaturedProjects.length) % FeaturedProjects.length);
  };

  const featuredProjects = () => {
    const currentProject = [];

    if (isMobile) {
      for (let i = 0; i < 2; i++) {
        const projectIndex = (currentIndex + i) % FeaturedProjects.length;
        const project = FeaturedProjects[projectIndex];
  
        currentProject.push(
          <Grid item xs={6} sm={6} md={4} sx={{paddingLeft: {xs:'5px !important'}, width:'100%'}}>
            <ProjectCard key={projectIndex} project={project} />
          </Grid>
  
        );
      }
    }else{
      for (let i = 0; i < 3; i++) {
        const projectIndex = (currentIndex + i) % FeaturedProjects.length;
        const project = FeaturedProjects[projectIndex];
  
        currentProject.push(
          <Grid item xs={6} sm={6} md={4} sx={{paddingLeft: {xs:'5px !important'}, width:'100%'}}>
            <ProjectCard key={projectIndex} project={project} />
          </Grid>
  
        );
      }
    }
  

    return currentProject;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //handle image change mossard
  let [Image, setImage] = useState(0);

  useEffect(() => {
    let timeout = setTimeout(() => {
        if (Image === (moImage.length - 1)) {
          setImage(0);
        } else {
          setImage(Image + 1);
        }
    }, 5000);

    return () => {
        clearTimeout(timeout);
    };
}, [Image]);
const moimagePath = `images/${moImage[Image]}`;

  return (
    <>
      <HomeSlider />
      <SectionBody>
        <FlexDivSpace>
          <div>
            <MainHeaderTag style={{ margin: '0' }}>FEATURED PROJECTS</MainHeaderTag >
            <MainPTag style={{ color: '#777777' }}>We exemplify excellence in designs, innovations, and creativity.</MainPTag>
          </div>
          <AutoDiv>
            <ArrowButton onClick={handlePrev}>
              <ExtentContactImage src='images/icons/back.png' />
            </ArrowButton>
            <ArrowButton onClick={handleNext}>
              <ExtentContactImage src='images/icons/front.png' />
            </ArrowButton>
          </AutoDiv>
        </FlexDivSpace>

        {/* <CardWrapper>
        </CardWrapper> */}
          <Grid container spacing={2} sx={{marginLeft:{sm:'0px !important', xs:'0px !important'}, margin:{sm:'0px !important', xs:'0px !important'}, width:{xs: '100% !important'}}}>
          {featuredProjects()}
          </Grid>
      
      

        <CenterButton>
          <MainButtonLink outline="true" to='portfolio'>
            view ALL PROJECTS <CardInfoAImage src='images/icons/frontmain.png' />
          </MainButtonLink>
        </CenterButton>
      </SectionBody>
      <BannerWrapper>
        <BannerTitle >BRANDS  THAT TRUST US</BannerTitle>
        <Slider>
          <SlideTrack>
            <SlideImg src='images/icons/amazon.png' />
            <SlideImg src='images/icons/youtube.png' />
            <SlideImg src='images/icons/unlocked.png' />
            <SlideImg src='images/icons/UNESCO.png' />
            <SlideImg src='images/icons/prime.png' />
            <SlideImg src='images/icons/bluenun.png' />
            <SlideImg src='images/icons/bigibet.png' />
            <SlideImg src='images/icons/mvca.png' />
            <SlideImg src='images/icons/mtn.png' />
            <SlideImg src='images/icons/sony.png' />
            <SlideImg src='images/icons/coke.png' />
            <SlideImg src='images/icons/betensured.jpg' />
            <SlideImg src='images/icons/netflix.png' />
            <SlideImg src='images/icons/redtv.png' />
            <SlideImg src='images/icons/heirs.png' />
            <SlideImg src='images/icons/loya.png' />
            <SlideImg src='images/icons/kwara.png' />
            <SlideImg src='images/icons/ilorin.png' />
            <SlideImg src='images/icons/cci.png' />
            <SlideImg src='images/icons/lafiya.png' />
            <SlideImg src='images/icons/elevation.png' />
            <SlideImg src='images/icons/ivvy.png' />
            
            <SlideImg src='images/icons/bbn.png' />
            <SlideImg src='images/icons/amazon.png' />
            <SlideImg src='images/icons/youtube.png' />
            <SlideImg src='images/icons/unlocked.png' />
            <SlideImg src='images/icons/UNESCO.png' />
            <SlideImg src='images/icons/prime.png' />
            <SlideImg src='images/icons/bluenun.png' />
            <SlideImg src='images/icons/bigibet.png' />
            <SlideImg src='images/icons/mvca.png' />
            <SlideImg src='images/icons/mtn.png' />
            <SlideImg src='images/icons/sony.png' />

          </SlideTrack>
        </Slider>
      </BannerWrapper>


      {/* <Testing /> */}
      <SectionBody>
        <MosardFlex>
          <MosardImg src={moimagePath} />
          <MosardAutoDiv>
            <MosardWord>
              Artist Commune
              <MosardWordRed>
                featured and interviewed
              </MosardWordRed>
              Big Mossad.
            </MosardWord>
            <MosardButtonDiv>
              <MainButtonLink smooth="true" duration={500} to="event"><MosaidPlayImage src='images/icons/play.png' />  Watch Interview</MainButtonLink>
            </MosardButtonDiv>

          </MosardAutoDiv>
        </MosardFlex>
      </SectionBody>

      <AllReviews />

      <Contact />
    </>
  )
}

export default Main