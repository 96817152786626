import styled from "styled-components";

export const TopRapper = styled.section`
width: 100%;
max-height: 550px;
height: 550px;
@media (min-width: 992px) and (max-width: 1199px) {
  height: 450px;
}

@media (min-width: 768px) and (max-width: 991px) {
  height: 350px;
}

@media (min-width: 576px) and (max-width: 767px) {
  height: 350px;
}
@media (max-width: 575px) {
    height: 250px;
  }
`; 
export const RapperImage = styled.div`
width: 100%;
height: 100%;
object-fit: cover;
object-position: center top;
background: linear-gradient(180.21deg, #000000 0.18%, rgba(0, 0, 0, 0) 0.18%, #000000 99.82%), url("/images/novideoproject.png");
display:flex;
justify-content: center;
align-items: center;
`; 
export const RapperVideo = styled.video`
width: 100%;
height: 100%;
`; 

