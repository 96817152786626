const reviews = [
    {
      id: 1,
      message: '“You did a great job bro, I love your work, You are ready for the takeover, You are the best!”',
      name: 'DR RICHARD AYODEJI MAKUN A.Y (MFR)',
      company: 'CEO Corporate World Entertainment',
    },
    {
      id: 2,
      message: ' “You did well, I definitely want to work with you again” ',
      name: 'Darlington Abuda ',
      company: 'Producer & Lead Consultant at PEEKABOO GLOBAL CONSULTING LTD',
    },
    {
      id: 3,
      message: '“Your works are always beautiful, I will definitely work with you again.”',
      name: 'Bukola Oloyede',
      company: 'Country Manager - Marketing & Partner Relations (Nigeria)',
    },
    {
      id: 4,
      message: '“You’re doing really well Mossad, Thanks for all your hardwork! I don’t have any issues with you.”',
      name: 'Anita Ashiru',
      company: 'CEO AA CREATIVES CO',
    },
    
    
  ];
  
  export default reviews;
  