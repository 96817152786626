import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.section`
background: #000000;
background-image: url("/images/footer.png");
background-size: cover;
background-position: center center;
// mix-blend-mode: soft-light;
@media (max-width: 575px) {
    background-image: url("/images/footer_mobile.png");
 }
`;

export const FooterFlex = styled.div`
display: flex;
justify-content: space-between;
gap: 2rem;
flex-wrap: wrap;
@media (min-width: 992px) and (max-width: 1199px) {
  
}

@media (min-width: 768px) and (max-width: 991px) {
  
}

@media (min-width: 576px) and (max-width: 767px) {
  // gap: 1rem;
}
@media (max-width: 575px) {
    display: none;
 }
`;

export const FooterDiv = styled.div`

`;
export const FooterTitle = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
text-transform: uppercase;
color: #FFFFFF;
 
@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) and (max-width: 991px) {
  
}

@media (min-width: 576px) and (max-width: 767px) {
  font-size: 18px;
}

@media (max-width: 575px) {
   font-size: 16px;
    line-height: 24px;
}
`;
export const FooterCopyRight = styled.p`
font-family: 'Circular Std';
font-style: normal;
font-weight: 450;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.01em;
color: #A1A1A1;
margin-left: 5rem;
margin-top: 5rem;
@media (max-width: 575px) {
    margin-left: 0;
margin-top: 3rem;
font-size: 14px;
line-height: 20px;
}
`;

export const FooterLogo = styled.img`
height: 47.28557205200195px;
width: 153.0418701171875px;
margin-top: 1.5rem;
margin-bottom: 3.5rem;
@media (min-width: 992px) and (max-width: 1199px) {
  width: 135.0418701171875px;
}

@media (min-width: 768px) and (max-width: 991px) {
  width: 100.0418701171875px;
  height: 35.28557205200195px;
}

@media (min-width: 576px) and (max-width: 767px) {
  width: 95.0418701171875px;
  height: 30.28557205200195px;
}
`;

export const FooterLink = styled(Link)`
font-family: 'Circular Std';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.04em;
color: #FFFFFF;
text-decoration: none;
display: block;
margin-top: 1rem;
text-transform: capitalize;
&:hover {
    color: #A1A1A1;
  }
@media (max-width: 998px) {
    font-size: 18px;
  }
  @media (max-width: 787px) {
    font-size: 16px;
  }

@media (max-width: 575px) {
    font-size: 13px;
    line-height: 24px;
}


`;

export const FooterLinkEmail = styled(FooterLink)`
text-transform: lowercase;
`;

export const FooterMobileView = styled.div`
display: none;
margin-top: 3rem;
@media (max-width: 575px) {
   display: block;
}
`;

