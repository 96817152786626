import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { PortfolioButton } from './portfolio.style'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PortfolioProjectCard from '../Card/PortfolioProjectCard';
import {  FlexDivSpacePort, PortWidth } from '../../styled-component/MainComponent';


const Portfolio = ({ projects, OpenFilter }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastProduct = currentPage * 10;
    const indexOfFirstProduct = indexOfLastProduct - (OpenFilter ? 10 : 9);

    const currentProducts = projects.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(projects.length / (OpenFilter ? 10 : 9));

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };
    return (
        <>
            <FlexDivSpacePort>
                {
                    currentProducts.map((item, index) => (
                       <PortWidth key={index}>
                            <PortfolioProjectCard key={item.id} filter={OpenFilter} project={item} />
                        </PortWidth>
                    ))
                }
                {
                    currentProducts.length > 9 &&
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            margin: 'auto',
                            marginTop: "50px"
                        }}
                    >
                        <PortfolioButton outline={currentPage === 1} disabled={currentPage === 1} onClick={handlePrevPage}> <ArrowBackIcon sx={{ fontSize: '17px' }} />   Previous</PortfolioButton>
                        <PortfolioButton outline={currentPage === totalPages} disabled={currentPage === totalPages} onClick={handleNextPage}> Next<ArrowForwardIcon sx={{ fontSize: '17px' }} /> </PortfolioButton>
                    </Stack>

                }
            </FlexDivSpacePort>
   
            {/* <Grid container spacing={2} sx={{marginLeft:{sm:'0px !important', xs:'0px !important'}, margin:{sm:'0px !important', xs:'0px !important'}}}>
                {
                    currentProducts.map((item) => (
                        <Grid item xs={6} sm={6} md={4}>
                            <PortfolioProjectCard key={item.id} filter={OpenFilter} project={item} />
                        </Grid>
                    ))
                }

                {
                    currentProducts.length > 9 &&
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            margin: 'auto',
                            marginTop: "50px"
                        }}
                    >
                        <PortfolioButton outline={currentPage === 1} disabled={currentPage === 1} onClick={handlePrevPage}> <ArrowBackIcon sx={{ fontSize: '17px' }} />   Previous</PortfolioButton>
                        <PortfolioButton outline={currentPage === totalPages} disabled={currentPage === totalPages} onClick={handleNextPage}> Next<ArrowForwardIcon sx={{ fontSize: '17px' }} /> </PortfolioButton>
                    </Stack>

                }

            </Grid> */}

        </>
    )
}

export default Portfolio